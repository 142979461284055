import { useEffect } from "react";
import { Navbar, Spinner } from "react-bootstrap";
import { HiExternalLink } from "react-icons/hi";

import { CmsConstructor } from "./cmsConstructor";
import { useDirty } from "../context/dirty";
import CmsEditAndSave from "./CmsEditAndSave";

const CmsNotifications = () => {
  const {
    cmsInstance,
    setCmsInstance,
    setMessage,
    setShowErrorModal: errorModal,
  } = useDirty();

  useEffect(() => {
    const cmsInstance = async () => {
      const cmsObj = await CmsConstructor(setMessage, errorModal);
      setCmsInstance(cmsObj);
    };
    cmsInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSuccessAndFailedNotifications = (
    message,
    key,
    notificationKey
  ) => {
    const notificationKeys = Object.keys(message);
    const notificationMessages = notificationKeys.map((notification, index) => {
      const description = message[notification].description;
      const messagePath = `cmsContent.notifications.${key}.${notificationKey}.${notification}.message`;

      return (
        <div key={index}>
          {description && (
            <h4 className="cms-notification-sub-heading mt-4 mb-0">
              {description}
            </h4>
          )}
          {cmsInstance &&
            cmsInstance.showRichText(messagePath, "notification-message")}
        </div>
      );
    });

    return notificationMessages;
  };

  const renderNotifications = (notificationContent, key, notificationKey) => {
    const content = notificationContent[notificationKey];
    if (content) {
      return renderSuccessAndFailedNotifications(content, key, notificationKey);
    }
  };

  const renderBlocks = () => {
    if (cmsInstance) {
      const {
        cmsContent: { notifications },
      } = cmsInstance.sendData();

      if (notifications) {
        const notificationBlocks = Object.keys(notifications).map(
          (key, index) => (
            <div
              className="pms-dialog-box p-4 mb-4"
              key={index}
              style={{ border: "3px solid #e4e6e9" }}
            >
              {notifications[key].heading && (
                <h4 className="cms-notification-heading">
                  {notifications[key].heading}
                </h4>
              )}
              {renderNotifications(notifications[key], key, "successContent")}
              {renderNotifications(notifications[key], key, "failContent")}
            </div>
          )
        );

        return notificationBlocks;
      }
    }

    return null;
  };

  return (
    <div>
      <Navbar
        fixed="top"
        className="navbar py-3 d-flex flex-row justify-content-center"
      >
        <div className="text-center">
          <strong className="navbar-heading">Danish CVR Lookup</strong>
          <br></br>
          <span className="navbar-subheading">
            Cloudify -
            <a
              className="navbar-subheading-link"
              href="https://www.cloudify.biz/hubspot-marketplace/contact-us"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              contact us
              <HiExternalLink className="hyperlink-icon" />
            </a>
          </span>
        </div>
      </Navbar>
      {!cmsInstance ? (
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <Spinner animation="border" variant="success" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className="d-flex flex-row justify-content-center"
            style={{ paddingTop: "100px" }}
          >
            <CmsEditAndSave />
          </div>
          <div className="d-flex flex-column align-items-center mt-4 pb-5">
            {renderBlocks()}
          </div>
        </>
      )}
    </div>
  );
};

export default CmsNotifications;
