import { Table, Button, Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import { useState } from "react";

import { getUrl } from "../helpers/url";

function SearchWindowTable({
  companiesData,
  getFinancialData,
  setSearchInput,
  setCompaniesData,
  rollbar,
  searchInput,
}) {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [message, setMessage] = useState("");
  const [storedId, setStoredId] = useState("");
  const [showLink, setShowLink] = useState(false);

  const addCompany = async (el) => {
    try {
      setStoredId(el["_id"]);
      setLoading(true);
      const portalId = searchParams.get("portalId");
      const objectId = searchParams.get("objectId");
      const body = {
        portalId,
        objectId,
        cvrNo: el["_source"]?.Vrvirksomhed?.cvrNummer,
      };
      await axios.post(getUrl("REACT_APP_UPDATE_COMPANIES_URL"), body);

      setMessage(
        "cmsContent.notifications.searchWindow.successContent.updateOrganization.message"
      );
      setShowSuccessModal(true);
      setSearchInput("");
      setCompaniesData([]);
    } catch (error) {
      console.log(error);
      rollbar.error("Error Adding Company", error, {
        portalId: searchParams.get("portalId"),
        objectId: searchParams.get("objectId"),
      });
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      setMessage(errorMessage);
      setShowErrorModal(true);
      if (error.response && error.response.status === 402) {
        setShowLink(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const hideErrorModal = () => {
    setShowErrorModal(false);
    setShowLink(false);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
    getFinancialData();
  };

  return (
    <>
      <SuccessModal
        show={showSuccessModal}
        message={message}
        hideSuccessModal={hideSuccessModal}
      />
      <ErrorModal
        show={showErrorModal}
        message={message}
        hideErrorModal={hideErrorModal}
        displayUrl={showLink}
      />
      <div className="search-table-container">
        {searchInput.length > 0 && companiesData.length > 0 && (
          <Table responsive className="search-table">
            <thead className="search-table-header">
              <tr>
                <th className="col-3 py-3">
                  <p className="ms-md-5 my-auto">COMPANY NAME</p>
                </th>
                <th className="col-3 py-3">
                  <p className="text-center my-auto">CVR NUMMER</p>
                </th>
                <th className="col-3 py-3">
                  <p className="text-center my-auto">STATUS</p>
                </th>
                <th className="col-3 py-3">
                  <p className="text-center my-auto">VIRKSOMHEDSFORM</p>
                </th>
                <th className="col-2 py-3"></th>
              </tr>
            </thead>
            <tbody className="search-table-body">
              {companiesData.map((el, index) => (
                <tr key={index}>
                  <td className="py-3">
                    <div className="d-flex flex-row">
                      <p className="search-table-body-para ms-md-5 my-auto">
                        <span className="search-table-company-name">
                          {
                            el["_source"]?.Vrvirksomhed?.virksomhedMetadata
                              ?.nyesteNavn?.navn
                          }
                        </span>
                        <br></br>
                        <span>
                          {
                            el["_source"]?.Vrvirksomhed?.virksomhedMetadata
                              ?.nyesteBeliggenhedsadresse?.vejnavn
                          }{" "}
                          {
                            el["_source"]?.Vrvirksomhed?.virksomhedMetadata
                              ?.nyesteBeliggenhedsadresse?.husnummerFra
                          }
                        </span>
                        <br></br>
                        <span>
                          {
                            el["_source"]?.Vrvirksomhed?.virksomhedMetadata
                              ?.nyesteBeliggenhedsadresse?.postnummer
                          }{" "}
                          {
                            el["_source"].Vrvirksomhed?.virksomhedMetadata
                              ?.nyesteBeliggenhedsadresse?.postdistrikt
                          }
                        </span>
                      </p>
                    </div>
                  </td>
                  <td className="py-3">
                    <p className="search-table-body-para text-center my-auto">
                      {el["_source"]?.Vrvirksomhed?.cvrNummer}
                    </p>
                  </td>
                  <td className="py-3">
                    <p className="search-table-body-para text-center my-auto">
                      {
                        el["_source"]?.Vrvirksomhed?.virksomhedMetadata
                          ?.sammensatStatus
                      }
                    </p>
                  </td>
                  <td className="py-3">
                    <p className="search-table-body-para text-center my-auto">
                      {
                        el["_source"]?.Vrvirksomhed?.virksomhedMetadata
                          ?.nyesteVirksomhedsform?.langBeskrivelse
                      }
                    </p>
                  </td>
                  <td className="py-3">
                    <Button
                      onClick={() => addCompany(el)}
                      className="search-table-btn my-auto mx-5"
                      disabled={loading}
                    >
                      {loading && storedId === el["_id"] ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span>Add</span>
                      )}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
}

export default SearchWindowTable;
