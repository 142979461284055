import { Navbar, Button, Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";

import { useRollbar } from "@rollbar/react";

import MappingWindow from "./MappingWindow";
import Subscription from "./Subscription";
import Confirmation from "./Confirmation";
import ErrorModal from "../components/ErrorModal";

import { getUrl } from "../helpers/url";

import { useDirty } from "../context/dirty";
import { CmsConstructor } from "../helpers/cmsConstructor";
import CmsEditAndSave from "../helpers/CmsEditAndSave";

const WINDOW = Object.freeze({
  MAPPING_WINDOW: "Mapping Window",
  SUBSCRIPTION: "Subscription Window",
  CONFIRMATION: "Confirmation Window",
});

const windowObject = {
  "Mapping Window": <MappingWindow />,
  "Subscription Window": <Subscription />,
  "Confirmation Window": <Confirmation />,
};

function NavBarPage() {
  const {
    isDirty,
    setCmsInstance,
    setMessage,
    setShowErrorModal: errorModal,
    cmsInstance,
    isEdit,
    setShowSaveModal,
    isCVRCheck,
    deleteHubspotFields,
  } = useDirty();
  const [currentWindow, setCurrentWindow] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const rollbar = useRollbar();

  let [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const showSaveModal = () => {
    setShowSaveModal(true);
  };

  const nextNavigation = () => {
    const portalId = searchParams.get("portalId");
    const userId = searchParams.get("userId");
    if (currentWindow === WINDOW.MAPPING_WINDOW) {
      navigate(
        `${window.location.pathname}?portalId=${portalId}&userId=${userId}&paymentStatus=none`
      );
      setCurrentWindow(WINDOW.SUBSCRIPTION);
    }
    if (currentWindow === WINDOW.SUBSCRIPTION) {
      navigate(
        `${window.location.pathname}?portalId=${portalId}&userId=${userId}&confirmation=true`
      );
      setCurrentWindow(WINDOW.CONFIRMATION);
    }
  };

  const backNavigation = () => {
    const portalId = searchParams.get("portalId");
    const userId = searchParams.get("userId");
    if (currentWindow === WINDOW.SUBSCRIPTION) {
      navigate(
        `${window.location.pathname}?portalId=${portalId}&userId=${userId}`
      );
      setCurrentWindow(WINDOW.MAPPING_WINDOW);
    }
    if (currentWindow === WINDOW.CONFIRMATION) {
      navigate(
        `${window.location.pathname}?portalId=${portalId}&userId=${userId}&paymentStatus=none`
      );
      setCurrentWindow(WINDOW.SUBSCRIPTION);
    }
  };

  const goBackToHubspot = () => {
    const portalId = searchParams.get("portalId");
    window.open(
      `https://app.hubspot.com/contacts/${portalId}/companies`,
      "_self"
    );
  };

  const hideErrorModal = () => setShowErrorModal(false);

  const install = async () => {
    try {
      const code = searchParams.get("code");
      const portalId = searchParams.get("portalId");
      const userId = searchParams.get("userId");
      let paymentStatus = searchParams.get("paymentStatus");
      let confirmation = searchParams.get("confirmation");
      if (code) {
        setLoading(true);
        let { data: res } = await axios.get(getUrl("REACT_APP_INSTALL_URL"), {
          params: {
            code,
          },
        });
        setCurrentWindow(WINDOW.MAPPING_WINDOW);
        setLoading(false);
        navigate(`../setup?portalId=${res.portalId}&userId=${res.userId}`);
      } else if (portalId && userId && paymentStatus) {
        setCurrentWindow(WINDOW.SUBSCRIPTION);
      } else if (portalId && userId && confirmation) {
        setCurrentWindow(WINDOW.CONFIRMATION);
      } else if (portalId && userId) {
        setCurrentWindow(WINDOW.MAPPING_WINDOW);
      }
    } catch (error) {
      console.log(error);
      rollbar.error("Error Installing App", error, {
        code: searchParams.get("code"),
        portalId: searchParams.get("portalId"),
      });
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMsg(message);
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    install();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cmsInStance = async () => {
      const cmsObj = await CmsConstructor(setMessage, errorModal);
      setCmsInstance(cmsObj);
    };
    cmsInStance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ErrorModal
        show={showErrorModal}
        message={errorMsg}
        hideErrorModal={hideErrorModal}
      />
      {loading ? (
        <div className="d-flex flex-column justify-content-center align-items-center nav-loading-container">
          <div className="d-flex flex-row justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      ) : (
        <div>
          <Navbar fixed="top" className="px-4 py-3 navbar">
            <div className="d-flex justify-content-between w-100">
              {currentWindow !== "Mapping Window" ? (
                <Button
                  className="navbar-back-button my-auto"
                  onClick={backNavigation}
                >
                  Back
                </Button>
              ) : (
                <p className="disable-para"></p>
              )}
              <div className="text-center">
                {cmsInstance &&
                  cmsInstance.showRichText(
                    "cmsContent.header.text",
                    "header-text"
                  )}
              </div>
              {currentWindow !== "Confirmation Window" ? (
                <>
                  {(isDirty || isCVRCheck || deleteHubspotFields.length > 0) &&
                  currentWindow === "Mapping Window" ? (
                    <Button
                      className="navbar-next-button my-auto"
                      onClick={() => showSaveModal()}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      className="navbar-next-button my-auto"
                      onClick={nextNavigation}
                    >
                      Next
                    </Button>
                  )}
                </>
              ) : (
                // <p className="disable-para"></p>
                <Button
                  className="navbar-next-button my-auto"
                  onClick={goBackToHubspot}
                >
                  Finish
                </Button>
              )}
            </div>
          </Navbar>

          <div className="container components-container">
            <div
              className="d-flex flex-column align-items-center"
              style={{
                marginTop:
                  isEdit && window.location.pathname === "/setup"
                    ? "200px"
                    : "",
              }}
            >
              <CmsEditAndSave />
            </div>
            <div>{windowObject[currentWindow]}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NavBarPage;
