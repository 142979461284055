import { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { useRollbar } from "@rollbar/react";
import { useSearchParams } from "react-router-dom";

import SearchWindowTable from "../components/SearchWindowTable";
import FinancialDataTable from "../components/FinancialDataTable";
import ErrorModal from "../components/ErrorModal";

import { getUrl } from "../helpers/url";

import { CmsConstructor } from "../helpers/cmsConstructor";
import { useDirty } from "../context/dirty";

function SearchWindow() {
  const {
    setCmsInstance,
    setMessage,
    setShowErrorModal: errorModal,
  } = useDirty();
  const [searchParams] = useSearchParams();
  const [companiesData, setCompaniesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [financialData, setFinancialData] = useState({});
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const rollbar = useRollbar();

  /**
   * @description This function is responsible for getting financial data
   */
  const getFinancialData = async () => {
    try {
      const objectId = searchParams.get("objectId");
      const portalId = searchParams.get("portalId");
      const { data: financialData } = await axios.get(
        getUrl("REACT_APP_GET_FINANCIAL_DATA"),
        {
          params: {
            objectId,
            portalId,
          },
        }
      );
      const financial = financialData.financial;

      if (financial && Object.keys(financial).length > 0) {
        const years = Object.keys(financial).sort().reverse();
        let data = {};
        for (let year of years) {
          data[`${year}`] = {
            "Net turnover": financial[`${year}`].revenue
              ? convertNumbers(financial[`${year}`].revenue)
              : "-",
            "Gross profit": financial[`${year}`].grossProfit
              ? convertNumbers(financial[`${year}`].grossProfit)
              : "-",
            "Net result": financial[`${year}`].profitLoss
              ? convertNumbers(financial[`${year}`].profitLoss)
              : "-",
            Equity: financial[`${year}`].equity
              ? convertNumbers(financial[`${year}`].equity)
              : "-",
            "Balance sheet": financial[`${year}`].status
              ? convertNumbers(financial[`${year}`].status)
              : "-",
            "Currency code": financial[`${year}`].currency
              ? financial[`${year}`].currency
              : "-",
          };
        }
        setFinancialData(data);
      } else {
        setFinancialData({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const convertNumbers = (value) => {
    const roundOffNumber = Math.round(parseInt(value) / 1000);
    return roundOffNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  /**
   * @description This function is used for searching the companies
   */
  const getSearchItems = async () => {
    try {
      setLoading(true);
      setDataFetched(false);
      if (searchInput === "")
        throw new Error("Search input should not be empty");

      // Condition for checking special characters
      let searchString = "";
      for (const char of searchInput) {
        if (/[æøåÆØÅ]/g.test(char)) {
          searchString += char;
        } else if (/[^a-zA-Z0-9 ]/g.test(char)) {
          searchString += `\\\\${char}`;
        } else {
          searchString += char;
        }
      }

      let { data: searchResults } = await axios.get(
        getUrl("REACT_APP_SEARCH_COMPANIES_URL"),
        {
          params: { keyword: searchString },
        }
      );

      if (searchResults.hits.hits.length === 0)
        throw new Error(`No company found with ${searchInput}`);

      setDataFetched(true);
      setCompaniesData(searchResults.hits.hits);
      setLoading(false);
    } catch (error) {
      rollbar.error("Error Searching companies", error, {
        searchInput: searchInput,
      });
      let message;
      setDataFetched(false);
      setLoading(false);
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMsg(message);
      setShowErrorModal(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      getSearchItems();
    } else if (e.target.value === "") {
      setCompaniesData([]);
    }
  };

  const hideErrorModal = () => setShowErrorModal(false);

  /**
   * @description This function used for warming the function while loading the iframe
   */
  const warmingFunction = async () => {
    try {
      await axios.post(getUrl("REACT_APP_UPDATE_COMPANIES_URL"), {
        warm: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * @description This function is responsible for getting subscription details
   */
  const fetchSubscriptionDetails = async () => {
    try {
      const portalId = searchParams.get("portalId");
      const { data: subscriptionData } = await axios.get(
        `${getUrl("REACT_APP_GET_PLAN_URL")}?portalId=${portalId}`
      );
      setSubscriptionDetails(subscriptionData);
    } catch (error) {
      console.log(error);
      let message;
      setDataFetched(false);
      setLoading(false);
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMsg(message);
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    let name = searchParams.get("name");
    if (name) {
      setSearchInput(name);
    }
    fetchSubscriptionDetails();
    warmingFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      subscriptionDetails &&
      Object.keys(subscriptionDetails).length > 0 &&
      (subscriptionDetails.plan === "premium" ||
        (subscriptionDetails.plan === "basic" &&
          subscriptionDetails.usageCount < 15))
    ) {
      getFinancialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionDetails]);

  useEffect(() => {
    const cmsInstance = async () => {
      const cmsObj = await CmsConstructor(setMessage, errorModal);
      setCmsInstance(cmsObj);
    };
    cmsInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="search-window-container">
      <ErrorModal
        show={showErrorModal}
        message={errorMsg}
        hideErrorModal={hideErrorModal}
      />
      <div className="container">
        <div className="row">
          <div className="col my-5">
            <div>
              {subscriptionDetails &&
                (subscriptionDetails.plan === "premium" ||
                  (subscriptionDetails.plan === "basic" &&
                    subscriptionDetails.usageCount < 15)) && (
                  <FinancialDataTable financialData={financialData} />
                )}
            </div>
            <div className="search-container px-5 mt-5">
              <div className="d-flex flex-row search-window-search-box">
                <input
                  type="search"
                  placeholder="Search..."
                  className="form-control search-window-search"
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  value={searchInput}
                />
                <button
                  className="search-window-search-button"
                  onClick={getSearchItems}
                >
                  <FiSearch className="my-auto mx-3 search-window-search-icon" />
                </button>
              </div>
            </div>

            <div className="search-table-loader-container">
              {dataFetched ? (
                <SearchWindowTable
                  companiesData={companiesData}
                  getFinancialData={getFinancialData}
                  setSearchInput={setSearchInput}
                  setCompaniesData={setCompaniesData}
                  rollbar={rollbar}
                  searchInput={searchInput}
                />
              ) : (
                <>
                  {loading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="d-flex flex-row justify-content-center align-items-center">
                        <Spinner
                          animation="border"
                          variant="primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchWindow;
