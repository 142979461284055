import React from "react";

import SetupGuideHeader from "../components/SetupGuideHeader";
import step1 from "../assets/images/activeCampaign/step_1.png";
import step2 from "../assets/images/activeCampaign/step_2.png";
import step3 from "../assets/images/activeCampaign/step_3.png";
import step4 from "../assets/images/activeCampaign/step_4.png";

const ActiveCampaignSetupGuide = () => {
  const navLinks = [
    {
      title: "Installing the app",
      navigationId: "#installingTheApp",
    },
  ];
  return (
    <div className="setup-guide-page">
      <SetupGuideHeader />
      <div className="container p-5">
        <div className="setup-guide-main-content">
          <div className="col-md-8 col-12">
            <div className="setup-guide-content-container">
              <h2 className="setup-guide-heading">
                How to use ActiveCampaign economic sync app
              </h2>
              <p className="setup-guide-para">
                By installing ActiveCampaign economic sync app, you can sync
                your contacts from ActiveCampaign to your economic account by
                abiding to the following steps:
              </p>

              <div id="installingTheApp">
                <h3 className="setup-guide-sub-heading">Installing the app</h3>
                <ol className="setup-guide-ordered-list">
                  <li className="py-2">
                    <p>
                      Open the ActiveCampaign app and log in to your account. On
                      the left menu, Go to <strong>Apps</strong> and install
                      Visma economic app.
                    </p>
                    <p>
                      <strong>Please Note:</strong> Automation creation is a
                      mandatory step in which you need to link the app for the
                      automation to work and set up triggers based on your
                      needs.
                    </p>
                  </li>
                  <li className="py-2">
                    <p>
                      Go to the <strong>Automation</strong> section. Click on{" "}
                      <strong>Create New Automation</strong>.
                    </p>
                  </li>
                  <li className="py-2">
                    <p>
                      The section shows you a few options, where you can click
                      on start from scratch and then click on Continue.
                    </p>
                  </li>
                  <li className="py-2">
                    <p>
                      You will now be redirected to the Trigger page after
                      clicking on the Start a Trigger button.
                    </p>
                  </li>
                  <li className="py-2">
                    <p>
                      A list of triggers will appear from where you need to
                      select one. Then continue with adding the app.
                    </p>
                  </li>
                  <li className="py-2">
                    <p>
                      Click on the <strong>CX Apps</strong> tab in your left
                      column, and select the <strong>economic app</strong>.
                    </p>
                    <img
                      src={step4}
                      alt="ActiveCampaign app window"
                      className="w-100"
                    />
                  </li>
                  <li className="py-2">
                    <p>
                      Once you have selected the action app, enter the Secret
                      token along with the Grant token.
                    </p>
                    <img
                      src={step1}
                      alt="ActiveCampaign app window"
                      className="w-100"
                    />
                  </li>
                  <li className="py-2">
                    <p>
                      This will lead you to the economic default mapping page,
                      which is a mandatory page to fill in.
                    </p>
                  </li>
                  <li className="py-2">
                    <p>
                      Enter the default values from the different categories
                      like customer groups, layouts, payment terms, and so on.
                      Then click on Continue.
                    </p>
                    <img
                      src={step2}
                      alt="ActiveCampaign app window"
                      className="w-100"
                    />
                    <p className="pt-3">
                      <strong>Please note:</strong> All these fields are
                      mandatory for economic app to add customers to
                      ActiveCampaign.
                    </p>
                  </li>
                  <li className="py-2">
                    <p>
                      The real mapping window opens where you need to add the
                      fields to be synced from ActiveCampaign to economic.
                    </p>
                    <img
                      src={step3}
                      alt="ActiveCampaign app window"
                      className="w-100"
                    />
                  </li>
                  <li className="py-2">
                    <p>
                      Click on Save and save the automation.Once Automation is
                      saved, activate the automation by changing automation
                      status to <strong>Active</strong>.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="col-md-2 setup-guide-nav-contents">
            <div className="setup-guide-contents">
              <h3 className="setup-guide-nav-header mt-2">CONTENTS</h3>
              <hr className="setup-guide-horizontal-line" />
              <ul className="setup-guide-ul">
                {navLinks.map((el, index) => (
                  <li key={index} className="py-1">
                    <a className="setup-guide-navlinks" href={el.navigationId}>
                      {el.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveCampaignSetupGuide;
