import { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { useRollbar } from "@rollbar/react";
import { Card, Spinner, Button } from "react-bootstrap";
import { FaExternalLinkAlt } from "react-icons/fa";

import PricingCard from "../components/PricingCard";
import ErrorModal from "../components/ErrorModal";
import SuccessModal from "../components/SuccessModal";

import { getStripeUrl, getUrl } from "../helpers/url";

import { CmsConstructor } from "../helpers/cmsConstructor";
import CmsEditAndSave from "../helpers/CmsEditAndSave";
import { useDirty } from "../context/dirty";

function Subscription() {
  const {
    cmsInstance,
    setCmsInstance,
    setMessage,
    setShowErrorModal: errorModal,
    showSuccessModal,
    hideSuccessModal,
    message,
  } = useDirty();
  const [searchParams] = useSearchParams();
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [customerKey, setCustomerKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const rollbar = useRollbar();

  /**
   * @description This function is used for fetching subscription details
   */
  const fetchPlanData = async () => {
    try {
      setLoading(true);
      const portalId = searchParams.get("portalId");

      let { data: subscriptionData } = await axios.get(
        getUrl("REACT_APP_GET_PLAN_URL"),
        {
          params: { portalId },
        }
      );
      // subscriptionData.plan = subscriptionData.plan.replace("-breached", "");
      setSubscriptionDetails(subscriptionData);
    } catch (error) {
      console.log(error);
      rollbar.error("Error Fetching Plan", error, {
        portalId: searchParams.get("portalId"),
      });
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMessage(message);
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  const hideErrorModal = () => setShowErrorModal(false);

  /**
   * @description This function is used for conditionally rendering the text
   * @returns {String}
   */
  const renderText = () => {
    if (subscriptionDetails.plan === "basic") {
      if (subscriptionDetails.usageCount >= 15) {
        return "The Trial period of this app has expired. To continue using it, please upgrade to the premium plan.";
      } else {
        return "You are currently subscribed to the Basic plan.";
      }
    } else if (subscriptionDetails.plan === "premium") {
      return "You are currently subscribed to the Premium plan.";
    } else if (subscriptionDetails.plan === "premium-cancelled") {
      return "Your Premium plan has been cancelled. Please upgrade to Premium to continue using the app";
    }
  };

  /**
   * @description This function is responsible for navigating to stripe customer portal
   */
  const onOpenStripeCustomerPortal = async () => {
    try {
      const portalId = searchParams.get("portalId");
      const userId = searchParams.get("userId");

      setButtonLoading(true);
      const { data: portal } = await axios.post(
        getStripeUrl("REACT_APP_STRIPE_BILLING_PORTAL"),
        {
          customerKey: subscriptionDetails.customerKey,
          returnUrl: `${window.location.origin}/redirect?portalId=${portalId}&userId=${userId}&paymentStatus=none`,
        }
      );

      if (portal.url) {
        window.open(portal.url, "_blank");
      }
    } catch (error) {
      console.log(error);
      rollbar.error("Error Fetching Plan", error, {
        portalId: searchParams.get("portalId"),
      });
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMessage(message);
      setShowErrorModal(true);
    } finally {
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    fetchPlanData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/subscription") {
      const cmsInstance = async () => {
        const cmsObj = await CmsConstructor({ setMessage, errorModal });
        setCmsInstance(cmsObj);
      };
      cmsInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ErrorModal
        show={showErrorModal}
        message={errorMessage}
        hideErrorModal={hideErrorModal}
      />
      <SuccessModal
        show={showSuccessModal}
        message={message}
        hideSuccessModal={hideSuccessModal}
      />
      {window.location.pathname === "/subscription" && (
        <div className="d-flex flex-column align-items-center">
          <CmsEditAndSave />
        </div>
      )}
      <div className="container">
        <div className="row">
          <div className="pricing-page d-flex justify-content-center align-tems-center">
            <div className="col-lg-6">
              <div className="my-4">
                {cmsInstance &&
                  cmsInstance.showRichText(
                    "cmsContent.subscription.headerText",
                    "pricing-page"
                  )}
                {loading ? (
                  <div className="d-flex flex-column justify-content-center align-items-center loading-container">
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <Spinner
                        animation="border"
                        variant="primary"
                        role="status"
                      ></Spinner>
                    </div>
                  </div>
                ) : (
                  <div>
                    <Card>
                      <Card.Body>
                        <p
                          className="my-1 pricing-page-para"
                          style={{
                            color: `${
                              subscriptionDetails.plan === "basic" &&
                              subscriptionDetails.usageCount >= 15 &&
                              "#f34541"
                            }`,
                            fontWeight: `${
                              subscriptionDetails.plan === "basic" &&
                              subscriptionDetails.usageCount >= 15 &&
                              "600"
                            }`,
                          }}
                        >
                          {renderText()}
                        </p>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <p className="my-0 pricing-page-para">
                            <b>Sync Usage: </b>
                            {subscriptionDetails.usageCount}
                          </p>
                          {subscriptionDetails.customerKey && (
                            <Button
                              className="add-button"
                              onClick={onOpenStripeCustomerPortal}
                            >
                              {buttonLoading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                <>
                                  <span>Go to billing portal</span>{" "}
                                  <FaExternalLinkAlt
                                    style={{ marginTop: "-3px" }}
                                  />
                                </>
                              )}
                            </Button>
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                    <PricingCard
                      subscriptionDetails={subscriptionDetails}
                      setSubscriptionDetails={setSubscriptionDetails}
                      rollbar={rollbar}
                      fetchPlanData={fetchPlanData}
                      setCustomerKey={setCustomerKey}
                      customerKey={customerKey}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Subscription;
