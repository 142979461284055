import { BsTriangleFill } from "react-icons/bs";

function MappingWindowHeader({ sortCVRFields, sortHSFields, hsSort, cvrSort }) {
  const sortCVR = () => {
    sortCVRFields();
  };

  const sortHS = () => {
    sortHSFields();
  };

  return (
    <tr>
      <th
        className={
          "window-header-heading " +
          (cvrSort.current !== undefined && "selected-header ")
        }
        onClick={sortCVR}
      >
        <div className="d-flex my-auto ms-3">
          CVR
          <span className="d-flex flex-column justify-content-around mx-2">
            <BsTriangleFill
              className={
                "normal-triangle " +
                (cvrSort.current === true && "selected-triangle ")
              }
            />
            <BsTriangleFill
              className={
                "rotated-triangle " +
                (cvrSort.current === false && "selected-triangle ")
              }
            />
          </span>
        </div>
      </th>
      <th style={{ width: "200px" }}></th>
      <th
        className={
          "window-header-heading " +
          (hsSort.current !== undefined && "selected-header ")
        }
        onClick={sortHS}
      >
        <div className="d-flex my-auto">
          HUBSPOT
          <span className="d-flex flex-column justify-content-around mx-2">
            <BsTriangleFill
              className={
                "normal-triangle " +
                (hsSort.current === true && "selected-triangle ")
              }
            />
            <BsTriangleFill
              className={
                "rotated-triangle " +
                (hsSort.current === false && "selected-triangle ")
              }
            />
          </span>
        </div>
      </th>
      <th className="text-center window-header-heading actions-container">
        <div>Action</div>
      </th>
    </tr>
  );
}

export default MappingWindowHeader;
