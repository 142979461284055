import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Configurator from "../components/Configurator";
import axios from "axios";
import ErrorModal from "../components/ErrorModal";
import SuccessModal from "../components/SuccessModal";
import { Spinner } from "react-bootstrap";
import { useRollbar } from "@rollbar/react";

import { getUrl } from "../helpers/url";

import { useDirty } from "../context/dirty";
import { CmsConstructor } from "../helpers/cmsConstructor";
import CmsEditAndSave from "../helpers/CmsEditAndSave";

function MappingWindow() {
  const {
    cmsInstance,
    setCmsInstance,
    showSuccessModal,
    message,
    hideSuccessModal,
    setMessage,
    setShowErrorModal: errorModal,
    setGetMappings,
    mappings,
    setShowErrorModal,
    showErrorModal,
  } = useDirty();
  //const [mappings, setMappings] = useState({});
  const [hsProperties, setHSProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [show, setShow] = useState(false);
  // const [errorMsg, setErrorMsg] = useState("");
  const [searchParams] = useSearchParams();
  const rollbar = useRollbar();

  /**
   * @description This function is responsible for fetching the HubSpot properties
   */
  const getHSProperties = async () => {
    try {
      const portalId = searchParams.get("portalId");

      const { data: hsProperties } = await axios.get(
        getUrl("REACT_APP_GET_HS_PROPERTIES"),
        { params: { portalId } }
      );

      setHSProperties(hsProperties);
    } catch (error) {
      console.log(error);
    }
  };

  const hideErrorModal = () => setShowErrorModal(false);

  useEffect(() => {
    fetchMappings({
      searchParams,
      setGetMappings,
      setLoading,
      rollbar,
      setMessage,
      setShowErrorModal,
    });
    getHSProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/mappingwindow") {
      const cmsInstance = async () => {
        const cmsObj = await CmsConstructor({ setMessage, errorModal });
        setCmsInstance(cmsObj);
      };
      cmsInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ErrorModal
        show={showErrorModal}
        message={message}
        hideErrorModal={hideErrorModal}
      />
      <SuccessModal
        show={showSuccessModal}
        message={message}
        hideSuccessModal={hideSuccessModal}
      />
      {window.location.pathname === "/mappingwindow" && (
        <div className="d-flex flex-column align-items-center">
          <CmsEditAndSave />
        </div>
      )}
      <div className="container mapping-window">
        <div className="row">
          <div className="col">
            <div className="my-5">
              {cmsInstance &&
                cmsInstance.showRichText(
                  "cmsContent.mappingWindow",
                  "mapping-window-header"
                )}
              <div>
                {loading ? (
                  <div className="d-flex flex-column justify-content-center align-items-center loading-container">
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <Spinner
                        animation="border"
                        variant="primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                      {/* <p className="my-1 mx-2 loading-text">Please wait...</p> */}
                    </div>
                  </div>
                ) : (
                  <Configurator
                    mappings={mappings}
                    getHSProperties={getHSProperties}
                    hsProperties={hsProperties}
                    rollbar={rollbar}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MappingWindow;

/**
 * @description This function is responsible for getting mappings
 */
export const fetchMappings = async ({
  searchParams,
  setGetMappings,
  setLoading,
  rollbar,
  setMessage,
  setShowErrorModal,
}) => {
  try {
    const portalId = searchParams.get("portalId");

    let { data } = await axios.get(getUrl("REACT_APP_GET_MAPPING_URL"), {
      params: { portalId },
    });

    //setMappings(data);
    setGetMappings(data);
    setLoading(false);
  } catch (error) {
    console.log(error);
    rollbar.error("Error Fetch Mappings", error, {
      portalId: searchParams.get("portalId"),
    });
    let message;
    if (error.response && error.response.data.message)
      message = error.response.data.message;
    else message = error.message;
    setMessage(message);
    setShowErrorModal(true);
  }
};
