import { Modal } from "react-bootstrap";
import { AiOutlineCheck } from "react-icons/ai";

import { useDirty } from "../context/dirty";

function SuccessModal({ show, message, hideSuccessModal }) {
  const { cmsInstance } = useDirty();

  const hideModal = () => {
    hideSuccessModal();
  };

  const checkContentFrom = () => {
    const successMessage = message.split(".");
    const messageFrom = successMessage.includes("cmsContent");
    return messageFrom;
  };

  return (
    <div>
      <Modal
        className="my-modal d-flex flex-row justify-content-center"
        onHide={hideModal}
        show={show}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="modal-msg-header">
            <div className="modal-check-box mx-auto" onClick={hideModal}>
              <AiOutlineCheck className="modal-check-icon" />
            </div>

            {checkContentFrom() ? (
              <>
                {cmsInstance &&
                  cmsInstance.showRichText(message, "modal-message")}
              </>
            ) : (
              <p className="modal-message p-3">{message}</p>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SuccessModal;
