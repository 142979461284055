import axios from "axios";

import Cms from "./Cms";

import { getCmsUrl } from "./url";

// this is the constructor that takes appName as the argument and fetch the data
export async function CmsConstructor({ setMessage, errorModal }) {
  try {
    let { data } = await axios.get(getCmsUrl("REACT_APP_GET_CMS_CONTENT"));

    return {
      // this function is used to make the data available for all the files
      sendData: function () {
        return data;
      },

      // this will return the PmCms component
      showRichText: function (path, cssName) {
        return <Cms path={path} cssName={cssName} data={data} />;
      },

      // this will update the content which we have in the data variable it will take object path and edited value as arguments
      onChangeContent: function (path, editedValue) {
        const properties = path.split(".");
        let current = data;
        for (let i = 0; i < properties.length - 1; i++) {
          const property = properties[i];
          current = current[property];
        }
        const lastProperty = properties[properties.length - 1];
        current[lastProperty] = editedValue;
      },

      // this function is used to save the changes into the database
      saveContentChanges: async function ({
        setMessage,
        setShowSuccessModal,
        setShowErrorModal,
      }) {
        try {
          await axios.post(getCmsUrl("REACT_APP_SAVE_CMS_CONTENT"), {
            appSlug: "hubspotCvrLookup",
            data: data,
          });
          setMessage("Content changes saved successfully");
          setShowSuccessModal(true);
        } catch (error) {
          let errorMessage;
          if (error.response && error.response.data.message)
            errorMessage = error.response.data.message;
          else errorMessage = error.message;
          setMessage(errorMessage);
          setShowErrorModal(true);
        }
      },
    };
  } catch (error) {
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    setMessage(errorMessage);
    errorModal(true);
  }
}
