import { createContext, useState, useContext } from "react";

const NavContext = createContext();

// Function responsible for checking context and returning it
export const useDirty = () => {
  const context = useContext(NavContext);
  if (context === undefined) {
    throw new Error("useDirty can only be used inside DirtyProvider");
  }
  return context;
};

export const DirtyProvider = ({ children }) => {
  //const dirtyHook = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [cmsInstance, setCmsInstance] = useState(undefined);
  const [isAllowedUser, setIsAllowedUser] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [allowedUsers, setAllowedUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const hideErrorModal = () => setShowErrorModal(false);
  const hideSuccessModal = () => setShowSuccessModal(false);
  const hideSaveModal = () => setShowSaveModal(false);
  const [mappings, setGetMappings] = useState([]);
  const [defaultMappings, setDefaultMapping] = useState({});
  const [isCVRCheck, setIsCVRCheck] = useState(false);
  const [deleteHubspotFields, setDeleteHubspotFields] = useState([]);
  const dirtyHook = {
    isDirty,
    setIsDirty,
    cmsInstance,
    setCmsInstance,
    isAllowedUser,
    setIsAllowedUser,
    isEdit,
    setIsEdit,
    allowedUsers,
    setAllowedUsers,
    message,
    setMessage,
    showErrorModal,
    setShowErrorModal,
    showSuccessModal,
    setShowSuccessModal,
    hideErrorModal,
    hideSuccessModal,
    showSaveModal,
    setShowSaveModal,
    hideSaveModal,
    mappings,
    setGetMappings,
    defaultMappings,
    setDefaultMapping,
    isCVRCheck,
    setIsCVRCheck,
    deleteHubspotFields,
    setDeleteHubspotFields,
  };
  return (
    <NavContext.Provider value={dirtyHook}>{children}</NavContext.Provider>
  );
};
