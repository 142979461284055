import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

function Pagination({
  bulkUpdateData,
  searchResults,
  searchInput,
  itemOffset,
  setItemOffset,
  setSlicedCompanies,
  pageCount,
  setPageCount,
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    if (
      Object.keys(bulkUpdateData).length > 0 &&
      searchResults.length > 0 &&
      searchInput
    ) {
      const endOffset = itemOffset + itemsPerPage;
      setSlicedCompanies(searchResults.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(searchResults.length / itemsPerPage));
    } else if (Object.keys(bulkUpdateData).length > 0) {
      const endOffset = itemOffset + itemsPerPage;
      setSlicedCompanies(
        bulkUpdateData.hsCompanies.slice(itemOffset, endOffset)
      );
      setPageCount(Math.ceil(bulkUpdateData.hsCompanies.length / itemsPerPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, itemsPerPage, bulkUpdateData, searchResults]);

  useEffect(() => {
    if (
      Object.keys(bulkUpdateData).length > 0 &&
      searchResults.length > 0 &&
      searchInput
    ) {
      const newOffset = (selectedIndex * itemsPerPage) % searchResults.length;
      setItemOffset(newOffset);
    } else {
      const newOffset =
        (selectedIndex * itemsPerPage) % bulkUpdateData.hsCompanies.length;
      setItemOffset(newOffset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  useEffect(() => {
    if (searchResults.length > 0) {
      setSelectedIndex(0);
    }
  }, [searchResults]);

  return (
    <div>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={(event) => setSelectedIndex(event.selected)}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName="pagination-container"
        pageLinkClassName="page-num"
        previousLinkClassName="page-num"
        nextLinkClassName="page-num"
        activeLinkClassName="active"
        disabledLinkClassName="disabled"
        marginPagesDisplayed={3}
        forcePage={selectedIndex}
      />
    </div>
  );
}

export default Pagination;
