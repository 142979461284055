import { Spinner } from "react-bootstrap";

import { useDirty } from "../context/dirty";

import SuccessModal from "./SuccessModal";

function AddCompanyInfo() {
  const { cmsInstance, message, showSuccessModal, hideSuccessModal } =
    useDirty();

  return (
    <>
      <SuccessModal
        show={showSuccessModal}
        message={message}
        hideSuccessModal={hideSuccessModal}
      />
      {!cmsInstance ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="d-flex flex-row justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      ) : (
        cmsInstance &&
        cmsInstance.showRichText(
          "cmsContent.confirmationPage",
          "confirmation-page"
        )
      )}
    </>
  );
}

export default AddCompanyInfo;
