import { Offcanvas, Button, Spinner } from "react-bootstrap";
import { useState } from "react";
import { BiCodeAlt } from "react-icons/bi";
import { BsCardText } from "react-icons/bs";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

import ErrorModal from "./ErrorModal";
import SuccessModal from "./SuccessModal";

import { getUrl } from "../helpers/url";

function SideBar({ getHSProperties, rollbar }) {
  const [searchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const [fieldName, setName] = useState("");
  const [fieldLabel, setLabel] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [description, setDescription] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /**
   * @description This function is responsible for creating new HubSpot property
   */
  const createHubspotProperty = () => {
    (async () => {
      let name;
      let label;
      if (
        fieldName !== "" &&
        fieldLabel !== "" &&
        fieldName !== undefined &&
        fieldLabel !== undefined
      ) {
        try {
          setLoading(true);

          if (/^\d/.test(fieldName))
            throw new Error("Name field should not start with Number");
          //eslint-disable-next-line
          if (/^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(fieldName))
            throw new Error(
              "Name field should not start with Special Character"
            );

          const newName = fieldName
            .trim(" ")
            .toLowerCase()
            .replace(/[^a-zA-Z0-9 ]/g, "")
            .split(" ")
            .join("_");
          name = newName;

          label = fieldLabel.trim(" ");

          let portalId = searchParams.get("portalId");
          let data = { label, name, portalId };
          if (description) data.description = description;
          await axios.post(getUrl("REACT_APP_CREATE_FIELD_URL"), data);
          await getHSProperties();
          setMessage(
            "cmsContent.notifications.mappingWindow.successContent.createProperty.message"
          );
          setShowSuccessModal(true);
        } catch (error) {
          console.log(error);
          rollbar.error("Error while Creating HubSpot Property", error, {
            portalId: searchParams.get("portalId"),
          });
          let errorMessage;
          if (error.response && error.response.data.message)
            errorMessage = error.response.data.message;
          else errorMessage = error.message;
          setMessage(errorMessage);
          setShowErrorModal(true);
        }
        setLoading(false);
        setName("");
        setLabel("");
        setDescription("");
        setShow(false);
      }
    })();
  };

  const onHandleName = (value) => setName(value);

  const onHandleLabel = (value) => setLabel(value);

  const hideSuccessModal = () => setShowSuccessModal(false);

  const hideErrorModal = () => setShowErrorModal(false);

  return (
    <div>
      <SuccessModal
        show={showSuccessModal}
        message={message}
        hideSuccessModal={hideSuccessModal}
      />
      <ErrorModal
        show={showErrorModal}
        message={message}
        hideErrorModal={hideErrorModal}
      />
      <button
        className="sidebar-cancel-button"
        // disabled={isDirty}
        onClick={handleShow}
      >
        Create new HubSpot property
      </button>

      <Offcanvas show={show} placement="end" onHide={handleClose}>
        <Offcanvas.Header className="sidebar-header" closeButton>
          <Offcanvas.Title>Create a new property</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <div className="my-4">
              <label className="form-label" htmlFor="customLabel">
                Label
              </label>
              <div className="d-flex flex-row">
                <input
                  id="customLabel"
                  type="text"
                  onChange={(e) => onHandleLabel(e.target.value)}
                  className="sidebar-label-input form-control"
                />
                <span className="mx-2 my-auto">
                  <BiCodeAlt size={30} className="code-icon" />
                </span>
              </div>
              <p className="hint-text mt-2">
                *This is the name of the property as it appears in your HubSpot
                CRM, including on records and index pages.
              </p>
            </div>

            <div className="my-4">
              <label className="form-label" htmlFor="customName">
                Name
              </label>
              <div className="d-flex flex-row">
                <input
                  id="customName"
                  type="text"
                  onChange={(e) => onHandleName(e.target.value)}
                  className="sidebar-label-input form-control"
                />
                <span className="mx-2 my-auto">
                  <BiCodeAlt size={30} className="code-icon" />
                </span>
              </div>
              <p className="hint-text mt-2">
                *This is the value of the property must contain only lowercase
                letters, numbers, and underscores. Also it must start with a
                letter.
              </p>
            </div>

            <div className="my-4">
              <label className="form-label" htmlFor="customName">
                Description
              </label>
              <div className="d-flex flex-row">
                <input
                  id="customName"
                  type="text"
                  onChange={(e) => setDescription(e.target.value)}
                  className="sidebar-label-input form-control"
                />
                <span className="mx-2 my-auto">
                  <BsCardText size={30} className="code-icon" />
                </span>
              </div>
              <p className="hint-text mt-2">
                *This is the Description of the HubSpot property.
              </p>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="sidebar-footer d-flex flex-column justify-content-center align-items-center">
          <div className="w-100 px-3 d-flex justify-content-between">
            <button onClick={handleClose} className="sidebar-cancel-button">
              Cancel
            </button>
            <Button
              disabled={
                fieldName === "" ||
                fieldLabel === "" ||
                fieldLabel.startsWith(" ") ||
                fieldName.startsWith(" ")
              }
              onClick={createHubspotProperty}
              className="sidebar-create-button"
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span>Create</span>
              )}
            </Button>
          </div>
        </div>
      </Offcanvas>
    </div>
  );
}

export default SideBar;
