import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import ProgressBarSection from "./ProgressBar";
import ErrorModal from "../ErrorModal";
import { getUrl } from "../../helpers/url";

function CompaniesList({
  show,
  selectedCompanies,
  setShowBulkUpdateModal,
  cvrNumberKey,
  showProgressBar,
  setShowProgressBar,
  getTime,
  settingIntervals,
  bulkUpdateStats,
  delayTime,
  delayTimeCalculation,
  intervalId,
  timeIntervalId,
  setBulkUpdateStats,
}) {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showLink, setShowLink] = useState(false);

  const hideErrorModal = () => {
    setShowErrorModal(false);
    setMessage("");
    setShowLink(false);
  };

  const onHandleCloseModal = () => {
    setShowBulkUpdateModal(false);
    timeIntervalId.current = null;
    intervalId.current = null;
  };

  /**
   * @description This function is used for fetching the subscription details
   */
  const fetchSubscriptionDetails = async (portalId) => {
    const { data: subscriptionData } = await axios.get(
      `${getUrl("REACT_APP_GET_PLAN_URL")}?portalId=${portalId}`
    );
    return subscriptionData;
  };

  /**
   * @description This function is responsible for performing bulk update
   */
  const onBulkUpdate = async () => {
    try {
      setIsUpdating(true);
      bulkUpdateStats.failedRecords = 0;
      bulkUpdateStats.pushedRecords = 0;
      bulkUpdateStats.processedRecords = 0;
      bulkUpdateStats.totalRecords = 0;
      setBulkUpdateStats({ ...bulkUpdateStats });
      const portalId = searchParams.get("portalId");

      const filteredCompanies = selectedCompanies.map((company) => {
        return {
          id: company.id,
          properties: {
            [cvrNumberKey]: company.properties[cvrNumberKey],
          },
        };
      });

      const subscription = await fetchSubscriptionDetails(portalId);

      if (subscription.usageCount >= 15 && subscription.plan === "basic") {
        setShowLink(true);
        throw new Error(
          "The Trial period of this app has expired. To continue using it, please upgrade to Premium plan."
        );
      }

      if (subscription.plan === "premium-cancelled") {
        setShowLink(true);
        throw new Error(
          "Your Premium plan has been cancelled. Please upgrade to premium to continue using this feature."
        );
      }

      if (
        subscription.plan === "basic" &&
        filteredCompanies.length > 15 - subscription.usageCount
      ) {
        setShowLink(true);
        throw new Error(
          "Credits are not sufficient to perform Bulk update in this Trial plan. Please upgrade to premium to continue using this feature."
        );
      }

      await axios.post(getUrl("REACT_APP_BULK_UPDATE_TRIGGER"), {
        portalId,
        filteredCompanies,
        cvrNumberKey,
      });
      setShowProgressBar(true);
      await getTime();
      settingIntervals();
    } catch (error) {
      console.log(error);
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setShowErrorModal(true);
      setMessage(message);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <>
      <ErrorModal
        show={showErrorModal}
        message={message}
        hideErrorModal={hideErrorModal}
        displayUrl={showLink}
      />

      <Modal show={show} centered className="bulk-modal">
        <Modal.Header>
          <Modal.Title>Bulk Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {showProgressBar ? (
              <div>
                <ProgressBarSection
                  bulkUpdateStats={bulkUpdateStats}
                  delayTime={delayTime}
                  delayTimeCalculation={delayTimeCalculation}
                />
              </div>
            ) : (
              <div>
                <p className="company-names-heading">Company names</p>
                <ul className="unordered-company-list">
                  {selectedCompanies.map((company) => (
                    <li key={company.id} className="my-1 company-name-text">
                      {company.properties.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {showProgressBar ? (
            <Button
              className="bulk-modal-cancel-btn"
              onClick={onHandleCloseModal}
            >
              Close
            </Button>
          ) : (
            <>
              <Button
                className="bulk-modal-update-btn"
                disabled={isUpdating}
                onClick={onBulkUpdate}
              >
                {isUpdating ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span>Update</span>
                )}
              </Button>
              <Button
                className="bulk-modal-cancel-btn"
                onClick={onHandleCloseModal}
                disabled={isUpdating}
              >
                Cancel
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CompaniesList;
