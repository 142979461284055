import { useMemo, useRef } from "react";
import ReactQuill from "react-quill";

import axios from "axios";

import { useDirty } from "../context/dirty";

// this PmCms function is used to render the richText and the content
const Cms = ({ path, cssName, data }) => {
  const quillRef = useRef();

  // this is the constructor instance we are getting from the context
  const {
    cmsInstance,
    isEdit,
    setAllowedUsers,
    allowedUsers: usersAllowed,
  } = useDirty();

  const { allowedUsers, cmsContent } = data;

  if (usersAllowed.length <= 0) {
    setAllowedUsers(allowedUsers);
  }

  const imageUpload = async (formData) => {
    try {
      const url = `${process.env.REACT_APP_STRAPI_BASE_URL}/api/upload`;
      const token = process.env.REACT_APP_CMS_TOKEN;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { data } = await axios.post(url, formData, { headers });
      return data;
    } catch (error) {
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      console.log(errorMessage);
    }
  };

  const imageHandler = async () => {
    const editor = quillRef.current.getEditor();
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        const formData = new FormData();
        formData.append("files", file);
        const res = await imageUpload(formData);
        const url = `${process.env.REACT_APP_STRAPI_BASE_URL}${res[0]?.url}`;
        editor.insertEmbed(editor.getSelection().index, "image", url);
      } else {
        console.log("Image upload failed");
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ font: [] }],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image", "video"],
          [{ align: [] }],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    // eslint-disable-next-line
    []
  );

  // this function returns the richText editor
  const showRichText = (path) => {
    let pathName = path;
    // eslint-disable-next-line
    pathName = cmsContent && eval(path);
    return (
      <div className="my-4">
        <ReactQuill
          theme="snow"
          ref={quillRef}
          value={pathName}
          onChange={(editedValue) =>
            cmsInstance.onChangeContent(path, editedValue)
          }
          className="editor-input"
          modules={modules}
        />
      </div>
    );
  };

  // this function returns the content when we are not editing
  const showText = (path, cssName) => {
    let pathName = path;
    // eslint-disable-next-line
    pathName = cmsContent && eval(path);
    return (
      <div
        dangerouslySetInnerHTML={{ __html: pathName }}
        className={`cms-content ${cssName}`}
      />
    );
  };

  return <>{isEdit ? showRichText(path) : showText(path, cssName)}</>;
};

export default Cms;
