import { useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

function HubspotSelectSearch({
  onChangeHubspotSelect,
  disableHubspotValue,
  onHandleHSSelect,
  selectDefaultProperty,
  mappedItem,
  cvrValue,
  editCVRValue,
  isCVRCheck,
  item,
  hsProperties,
}) {
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [hsEditedValue, setHSEditedValue] = useState("");

  const editSelectedValue = (hsProperty) => {
    const property = JSON.parse(hsProperty);
    setHSEditedValue(property.label);
    onHandleHSSelect(property);
  };

  const selectDefault = (value) => {
    return selectDefaultProperty(value);
  };

  const disableHubspot = (item, value) => {
    return disableHubspotValue(item, value);
  };

  /**
   * @description This function is used for searching the HubSpot property in the dropdown
   * @param {Object} value Search input entered
   */
  const searchItems = (value) => {
    setSearchInput(value);
    const filteredData = hsProperties.filter((el) => {
      return el.label.toLowerCase().includes(value.toLowerCase());
    });

    setSearchResults(filteredData);
  };

  /**
   * @description This function is used for performing validations
   * @param {Object} hsProperty HubSpot property
   * @param {String} cvrFieldType CVR property type
   * @returns a boolean value
   */
  const disableHubspotFields = (hsProperty, cvrFieldType) => {
    if (cvrValue && cvrValue.length > 0 && cvrValue[1]?.type) {
      if (cvrValue[1]?.type === "number") {
        return hsProperty.type === "string" || hsProperty.type === "number";
      } else if (
        cvrValue[1].type === "string" ||
        cvrValue[1].type === "date" ||
        cvrValue[1].type === "boolean"
      ) {
        return hsProperty.type === "string";
      }
    } else if (
      editCVRValue &&
      editCVRValue.length > 0 &&
      editCVRValue[1]?.type
    ) {
      if (editCVRValue[1]?.type === "number") {
        return hsProperty.type === "string" || hsProperty.type === "number";
      } else if (
        editCVRValue[1].type === "string" ||
        editCVRValue[1].type === "date" ||
        editCVRValue[1].type === "boolean"
      ) {
        return hsProperty.type === "string";
      }
    } else if (cvrFieldType && editCVRValue && editCVRValue.length === 0) {
      if (cvrFieldType === "number") {
        return hsProperty.type === "string" || hsProperty.type === "number";
      } else if (
        cvrFieldType === "string" ||
        cvrFieldType === "boolean" ||
        cvrFieldType === "date"
      ) {
        return hsProperty.type === "string";
      }
    } else {
      return true;
    }
  };

  return (
    <div>
      {isCVRCheck ? (
        <Dropdown onSelect={editSelectedValue}>
          <Dropdown.Toggle
            id="dropdown-basic"
            className="drop-down-button dropdown-select"
          >
            {hsEditedValue === "" ? "Select" : hsEditedValue}
          </Dropdown.Toggle>
          <Dropdown.Menu
            renderOnMount={true}
            popperConfig={{ strategy: "fixed" }}
            className="dropdown-container"
          >
            {hsProperties.length === 0 ? (
              <div className="d-flex flex-column justify-content-center align-items-center ">
                <Spinner animation="border" variant="primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <div className="p-3 dropdown-search-container">
                  <div className="d-flex flex-row dropdown-search-box">
                    <input
                      type="search"
                      placeholder="search.."
                      className="w-100 dropdown-search"
                      onChange={(e) => searchItems(e.target.value)}
                    />
                    <span className="my-auto">
                      <FaSearch size={15} className="me-2 search-icon" />
                    </span>
                  </div>
                </div>
                <div className="dropdown-items-container">
                  {(searchInput.length > 1 ? searchResults : hsProperties)
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((el) => (
                      <Dropdown.Item
                        className={
                          "select-options " +
                          (disableHubspot(item, el.value) &&
                          el.value !== selectDefault(mappedItem[1]).hsValue
                            ? "disabled-option "
                            : "")
                        }
                        disabled={
                          (disableHubspot(item, el.value) &&
                            el.value !==
                              selectDefault(mappedItem[1]).hsValue) ||
                          !disableHubspotFields(el, mappedItem[1].type)
                        }
                        selected={
                          el.value === selectDefault(mappedItem[1]).hsValue
                        }
                        eventKey={JSON.stringify(el)}
                        key={el.value}
                        value={el.value}
                      >
                        {el.label}
                      </Dropdown.Item>
                    ))}
                </div>
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <Dropdown onSelect={editSelectedValue}>
          <Dropdown.Toggle
            id="dropdown-basic"
            className="dropdown-button dropdown-select"
          >
            {hsEditedValue === ""
              ? selectDefault(mappedItem[1]).hsLabel
              : hsEditedValue}
          </Dropdown.Toggle>

          <Dropdown.Menu
            renderOnMount={true}
            popperConfig={{ strategy: "fixed" }}
            className="dropdown-container"
          >
            {hsProperties.length === 0 ? (
              <div className="d-flex flex-column justify-content-center align-items-center ">
                <Spinner animation="border" variant="primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <div className="p-3 dropdown-search-container">
                  <div className="d-flex flex-row dropdown-search-box">
                    <input
                      type="search"
                      placeholder="search.."
                      className="w-100 dropdown-search"
                      onChange={(e) => searchItems(e.target.value)}
                    />
                    <span className="my-auto">
                      <FaSearch size={15} className="me-2 search-icon" />
                    </span>
                  </div>
                </div>
                <div className="dropdown-items-container">
                  {(searchInput.length > 1 ? searchResults : hsProperties)
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((el) => (
                      <Dropdown.Item
                        className={
                          "select-options " +
                          (disableHubspot(item, el.value) &&
                          el.value !== selectDefault(mappedItem[1]).hsValue
                            ? "disabled-option "
                            : "")
                        }
                        disabled={
                          (disableHubspot(item, el.value) &&
                            el.value !==
                              selectDefault(mappedItem[1]).hsValue) ||
                          !disableHubspotFields(el, mappedItem[1].type)
                        }
                        selected={
                          el.value === selectDefault(mappedItem[1]).hsValue
                        }
                        eventKey={JSON.stringify(el)}
                        key={el.value}
                        value={el.value}
                      >
                        {el.label}
                      </Dropdown.Item>
                    ))}
                </div>
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
}

export default HubspotSelectSearch;
