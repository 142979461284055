import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import MappingWindow from "./pages/MappingWindow";
import Subscription from "./pages/Subscription";
import Confirmation from "./pages/Confirmation";
import NavBarPage from "./pages/NavBarPage";
import SearchWindow from "./pages/SearchWindow";
import SetupGuidePage from "./pages/SetupGuide";
import BulkUpdate from "./pages/BulkUpdate";
import Redirect from "./pages/Redirect";
import CmsNotifications from "./helpers/CmsNotifications";
import { Provider } from "@rollbar/react";
import { DirtyProvider } from "./context/dirty";

import ActiveCampaignSetupGuide from "./pages/ActiveCampaignSetupGuide";

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.NODE_ENV,
  },
};

function App() {
  return (
    <DirtyProvider>
      <Provider config={rollbarConfig}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/setup" element={<NavBarPage />}></Route>
            <Route path="/mappingwindow" element={<MappingWindow />}></Route>
            <Route path="/subscription" element={<Subscription />}></Route>
            <Route path="/confirmation" element={<Confirmation />}></Route>
            <Route path="/searchwindow" element={<SearchWindow />}></Route>
            <Route path="/setupguide" element={<SetupGuidePage />}></Route>
            <Route path="/bulkupdate" element={<BulkUpdate />}></Route>
            <Route path="/redirect" element={<Redirect />}></Route>
            <Route
              path="/edit-notifications"
              element={<CmsNotifications />}
            ></Route>
            <Route
              path="/activecampaign-setupguide"
              element={<ActiveCampaignSetupGuide />}
            ></Route>
          </Routes>
        </Router>
      </Provider>
    </DirtyProvider>
  );
}

export default App;
