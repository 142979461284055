import React, { useEffect, useState, useRef } from "react";
import { Container, Table, Button, Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import axios from "axios";

import SearchResultsNotFound from "../components/SearchResultsNotFound";
import CompaniesList from "../components/bulkUpdate/CompaniesList";
import ErrorModal from "../components/ErrorModal";
import Pagination from "../components/bulkUpdate/Pagination";

import { getUrl } from "../helpers/url";

function BulkUpdate() {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [bulkUpdateData, setBulkUpdateData] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [showBulkUpdateModal, setShowBulkUpdateModal] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [bulkUpdateStats, setBulkUpdateStats] = useState({});
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [delayTime, setDelayTime] = useState(0);
  const currentTime = useRef();
  const timeIntervalId = useRef();
  const intervalId = useRef();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [slicedCompanies, setSlicedCompanies] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  /**
   * @description This function is responsible for fetching hs companies with CVR Number
   */
  const fetchHSCompanies = async () => {
    try {
      setIsLoading(true);
      const portalId = searchParams.get("portalId");

      if (!portalId) throw new Error("Portal Id is missing");

      // Fetch Mappings
      let { data } = await axios.get(getUrl("REACT_APP_GET_MAPPING_URL"), {
        params: { portalId },
      });

      const cvrNumberKey = data?.basicMappings["CVR Number"].hsValue;
      if (!cvrNumberKey || cvrNumberKey === "undefined")
        throw new Error(
          "Please map the CVR Number in the mapping window to use this feature."
        );

      const companiesList = [];

      // Parameters required for fetching HS companies having CVR Numbers
      let after = 0;
      let limit = 100;

      const { data: companies } = await axios.get(
        getUrl("REACT_APP_FETCH_HS_COMPANIES"),
        {
          params: {
            portalId,
            after,
            limit,
            filterProperty: cvrNumberKey,
          },
        }
      );

      companiesList.push(...companies.results);

      // Getting total count of all companies
      const totalCompaniesCount = companies.total;
      // Calculate number of iterations required to fetch all companies
      const noOfIterations =
        Math.floor(Math.ceil(totalCompaniesCount / limit)) - 1;

      if (noOfIterations > 0) {
        after = companies?.paging.next.after;
        for (let i = 0; i < noOfIterations; i++) {
          // Fetch companies
          const { data: companies } = await axios.get(
            getUrl("REACT_APP_FETCH_HS_COMPANIES"),
            {
              params: {
                portalId,
                after,
                limit,
                filterProperty: cvrNumberKey,
              },
            }
          );

          companiesList.push(...companies.results);

          if (companies.paging && companies?.paging.next.after) {
            after = companies.paging.next.after;
          }
        }
      }

      // resetting the search params
      after = 0;

      // Adding input variable
      let hsCompanies = [];
      companiesList.forEach((company) => {
        company.isChecked = false;
        hsCompanies.push(company);
      });

      setBulkUpdateData({
        hsCompanies,
        cvrNumberKey,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * @description This function is responsible for searching companies
   * @param {String} searchValue value entered in the input
   */
  const onSearchCompanies = (searchValue) => {
    setSearchInput(searchValue);
    if (searchValue !== "") {
      const filteredCompanies = bulkUpdateData.hsCompanies.filter((company) => {
        return (
          company.properties.name
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          company.properties[bulkUpdateData.cvrNumberKey]
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        );
      });
      setSearchResults(filteredCompanies);
    } else {
      setSearchResults([]);
    }
  };

  /**
   * @description This function is responsible for storing and updating HubSpot companies
   * @param {Object} event event object of the check box
   * @param {String} companyId HubSpot company Id
   */
  const onSelectCompany = (event, companyId) => {
    setIsSelectAll(false);
    if (event.target.checked) {
      // Finding index of the Hubspot company
      const index = bulkUpdateData.hsCompanies.findIndex(
        (company) => company.id === companyId
      );
      // Finding the selected company
      const companyFound = bulkUpdateData.hsCompanies[index];

      companyFound.isChecked = true;
      // Updating state for the selected company
      bulkUpdateData.hsCompanies.splice(index, 1, companyFound);
      setBulkUpdateData({ ...bulkUpdateData });
    } else if (!event.target.checked) {
      // Finding index of the Hubspot company
      const index = bulkUpdateData.hsCompanies.findIndex(
        (company) => company.id === companyId
      );
      // Finding the selected company
      const companyFound = bulkUpdateData.hsCompanies[index];
      companyFound.isChecked = false;
      // Updating state for the selected company
      bulkUpdateData.hsCompanies.splice(index, 1, companyFound);
      setBulkUpdateData({ ...bulkUpdateData });
    }
  };

  /**
   * @description This function is responsible for selecting and unselecting all the companies
   * @param {Object} event event object of the checkbox
   */
  const onSelectAllCompanies = (event) => {
    if (event.target.checked) {
      setIsSelectAll(true);
      bulkUpdateData.hsCompanies.forEach(
        (company) => (company.isChecked = true)
      );
      setBulkUpdateData({ ...bulkUpdateData });
    } else if (!event.target.checked) {
      setIsSelectAll(false);
      bulkUpdateData.hsCompanies.forEach(
        (company) => (company.isChecked = false)
      );
      setBulkUpdateData({ ...bulkUpdateData });
    }
  };

  /**
   * @description This function is responsible for getting time for third party API
   */
  const getTime = async () => {
    const { data: timeData } = await axios.get(
      `${getUrl("REACT_APP_GET_CURRENT_TIME")}?timeZone=Europe/Copenhagen`
    );
    currentTime.current = timeData.dateTime;
  };

  /**
   * @description This function is responsible for fetching current time for third party API at regular intervals
   */
  const intervalToGetTime = () => {
    timeIntervalId.current = setInterval(async () => {
      await getTime();
    }, 60000);
  };

  /**
   * @description This function is responsible for calculating delay in minutes
   * @param {String} startTime start time of the bulk update
   * @param {String} currentTime current time
   * @returns {String} difference between current and start time
   */
  const updationDelay = (startTime, currentTime) => {
    const currentDate = new Date(currentTime);
    const storedDate = new Date(startTime);
    let delayMin = (currentDate.getTime() - storedDate.getTime()) / 1000;
    delayMin = delayMin / 60;
    return Math.abs(Math.round(delayMin));
  };

  /**
   * @description This function is responsible for calculating the delay time based on pushed records
   * @param {Number} records total records count
   * @returns {Number} calculated delay time
   */
  const delayTimeCalculation = (records) => {
    const calculatedDelay = Math.ceil((2 * records) / 20);
    return calculatedDelay;
  };

  /**
   * @description This function is responsible for getting bulk update stats from DynamoDB
   * @returns {Object} stats
   */
  const getBulkUpdateStats = async () => {
    const portalId = searchParams.get("portalId");

    const { data: stats } = await axios.get(
      getUrl("REACT_APP_GET_BULK_UPDATE_STATS"),
      {
        params: {
          portalId,
        },
      }
    );
    return stats;
  };

  /**
   * @description This function is responsible for setting interval for fetching stats
   */
  const settingIntervals = () => {
    intervalId.current = setInterval(async () => {
      const stats = await getBulkUpdateStats();
      const { failedRecords, processedRecords, pushedRecords, startTime } =
        stats;
      setBulkUpdateStats(stats);
      if (!timeIntervalId.current) {
        intervalToGetTime();
      }
      const delayInMins = updationDelay(startTime, currentTime.current);
      setDelayTime(delayInMins);
      if (
        pushedRecords === processedRecords + failedRecords ||
        delayInMins >= delayTimeCalculation(pushedRecords)
      ) {
        clearInterval(intervalId.current);
        intervalId.current = null;
        clearInterval(timeIntervalId.current);
        timeIntervalId.current = null;
      }
    }, 3000);
  };

  /**
   * @description This function is responsible for showing modal
   */
  const onShowBulkUpdateModal = async () => {
    try {
      setLoading(true);
      const filteredCompanies = filterHSCompanies();
      if (filteredCompanies.length === 0)
        throw new Error(
          "No companies selected. Please select the companies to use this feature."
        );
      setSelectedCompanies(filteredCompanies);

      // Fetch Bulk update stats
      const stats = await getBulkUpdateStats();

      if (stats && Object.keys(stats).length === 0) {
        setShowBulkUpdateModal(true);
        setShowProgressBar(false);
      } else {
        const { failedRecords, processedRecords, pushedRecords, startTime } =
          stats;
        await getTime();
        intervalToGetTime();
        const delayInMins = updationDelay(startTime, currentTime.current);
        if (
          pushedRecords === failedRecords + processedRecords ||
          delayInMins >= delayTimeCalculation(pushedRecords)
        ) {
          clearInterval(timeIntervalId.current);
          timeIntervalId.current = null;
          setShowBulkUpdateModal(true);
          setShowProgressBar(false);
        } else {
          setShowBulkUpdateModal(true);
          setBulkUpdateStats(stats);
          setShowProgressBar(true);
          settingIntervals();
        }
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  const hideErrorModal = () => {
    setErrorMessage("");
    setShowErrorModal(false);
  };

  const convertDate = (date) => {
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const newDate = new Date(date);
    return `${
      month[newDate.getMonth()]
    } ${newDate.getDate()}, ${newDate.getFullYear()}`;
  };

  /**
   * @description This function is used for filtering the companies which were checked
   * @returns {Array} filtered companies
   */
  const filterHSCompanies = () => {
    if (
      bulkUpdateData &&
      Object.keys(bulkUpdateData).length > 0 &&
      bulkUpdateData.hsCompanies.length > 0
    ) {
      const filteredCompanies = bulkUpdateData.hsCompanies.filter(
        (company) => company.isChecked === true
      );
      return filteredCompanies;
    }
  };

  useEffect(() => {
    fetchHSCompanies();
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
      if (timeIntervalId.current) {
        clearInterval(timeIntervalId.current);
      }
      intervalId.current = null;
      timeIntervalId.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <CompaniesList
        show={showBulkUpdateModal}
        selectedCompanies={selectedCompanies}
        setShowBulkUpdateModal={setShowBulkUpdateModal}
        cvrNumberKey={bulkUpdateData.cvrNumberKey}
        showProgressBar={showProgressBar}
        setShowProgressBar={setShowProgressBar}
        getTime={getTime}
        settingIntervals={settingIntervals}
        bulkUpdateStats={bulkUpdateStats}
        setBulkUpdateStats={setBulkUpdateStats}
        delayTime={delayTime}
        delayTimeCalculation={delayTimeCalculation}
        intervalId={intervalId}
        timeIntervalId={timeIntervalId}
      />

      <ErrorModal
        show={showErrorModal}
        message={errorMessage}
        hideErrorModal={hideErrorModal}
      />

      <Container className="my-5">
        {isLoading ? (
          <div className="d-flex flex-column justify-content-center align-items-center loading-container">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <Spinner
                animation="border"
                variant="primary"
                role="status"
              ></Spinner>
            </div>
          </div>
        ) : (
          <>
            {Object.keys(bulkUpdateData).length > 0 &&
            bulkUpdateData.hsCompanies.length > 0 &&
            bulkUpdateData.cvrNumberKey ? (
              <div>
                <p className="progress-bar-normal-text">
                  By exporting data from the CVR Lookup app that matches the
                  fields in HubSpot, you can easily update multiple records at
                  once. This feature is especially useful for managing large
                  amounts of data and ensuring that your HubSpot CRM remains
                  accurate and up to date. Please note: Companies with invalid
                  CVR numbers will be excluded from the update.
                </p>
                <div className="d-flex flex-row justify-content-between">
                  <div className="mapping-search d-flex my-3 my-md-0">
                    <input
                      type="search"
                      placeholder="Search.."
                      className="input-search"
                      onChange={(e) => onSearchCompanies(e.target.value)}
                    />
                    <span className="my-auto">
                      <FaSearch size={15} className="me-3 search-icon" />
                    </span>
                  </div>
                  <Button
                    onClick={() => onShowBulkUpdateModal()}
                    className="bulk-update-button"
                    disabled={loading || filterHSCompanies().length === 0}
                  >
                    {loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <span>Bulk update</span>
                    )}
                  </Button>
                </div>

                {searchResults.length === 0 && searchInput.length > 0 ? (
                  <SearchResultsNotFound
                    displayText={"No companies found with the search."}
                    noteText={"Try changing your filters."}
                  />
                ) : (
                  <>
                    <Table
                      className="company-table my-4"
                      bordered={true}
                      responsive={true}
                    >
                      <thead className="companies-table-header">
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              id="selectAll"
                              checked={isSelectAll}
                              onChange={(e) => onSelectAllCompanies(e)}
                              className="company-table-checkbox"
                            />
                          </th>
                          <th>COMPANY NAME</th>
                          <th>{bulkUpdateData.cvrNumberKey}</th>
                          <th>DOMAIN</th>
                          <th>CREATE DATE</th>
                        </tr>
                      </thead>
                      {slicedCompanies && slicedCompanies.length > 0 && (
                        <tbody className="companies-table-body">
                          {slicedCompanies.map((company) => (
                            <tr key={company.id}>
                              <td>
                                <input
                                  type="checkbox"
                                  id={company.id}
                                  checked={company.isChecked}
                                  onChange={(e) =>
                                    onSelectCompany(e, company.id)
                                  }
                                  className="company-table-checkbox"
                                />
                              </td>
                              <td className="company-name">
                                {company.properties.name}
                              </td>
                              <td>
                                {company.properties[bulkUpdateData.cvrNumberKey]
                                  ? company.properties[
                                      bulkUpdateData.cvrNumberKey
                                    ]
                                  : "-"}
                              </td>
                              <td>
                                {company.properties.domain
                                  ? company.properties.domain
                                  : "-"}
                              </td>
                              <td>
                                {company.properties.createdate
                                  ? convertDate(company.properties.createdate)
                                  : "-"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </Table>

                    <Pagination
                      bulkUpdateData={bulkUpdateData}
                      searchResults={searchResults}
                      searchInput={searchInput}
                      itemOffset={itemOffset}
                      setItemOffset={setItemOffset}
                      setSlicedCompanies={setSlicedCompanies}
                      pageCount={pageCount}
                      setPageCount={setPageCount}
                    />
                  </>
                )}
              </div>
            ) : (
              <SearchResultsNotFound
                displayText={"No companies found."}
                noteText={
                  "Please map the CVR Number in the Mapping Window and also provide the valid CVR Number in the HubSpot companies."
                }
              />
            )}
          </>
        )}
      </Container>
    </div>
  );
}

export default BulkUpdate;
