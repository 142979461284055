import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";

import arrowIcon from "../assets/images/arrowIcon.png";

function MappedRows({
  mappedItem,
  selectDefaultProperty,
  onEditMapping,
  deleteExistingMapping,
  addMapping,
  storedKey,
}) {
  const selectDefault = (value) => {
    return selectDefaultProperty(value);
  };

  const editMapping = (value) => {
    onEditMapping(value);
  };

  const deleteExisting = (value) => {
    deleteExistingMapping(value);
  };

  return (
    <>
      <td className="cvr-name-container ps-4">
        <span className="cvr-name my-auto">{mappedItem[0]}</span> <br />
        <span className="cvr-description my-auto">
          {mappedItem[1].cvrFieldDescription}
        </span>
      </td>
      <td className="text-center">
        <img src={arrowIcon} alt="arrow-icon" className="arrow-icon" />
      </td>
      <td>
        <p
          className={
            selectDefault(mappedItem[1]).hsLabel ===
            "HubSpot custom property deleted"
              ? "deleted-hs-name my-auto"
              : "hs-name my-auto"
          }
        >
          {selectDefault(mappedItem[1]).hsLabel === "" ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  To add mapping for this field click Action-edit or leave as it
                  is to not map this field.
                </Tooltip>
              }
            >
              <span style={{ cursor: "pointer" }}>Not mapped</span>
            </OverlayTrigger>
          ) : (
            selectDefault(mappedItem[1]).hsLabel
          )}
        </p>
        <span className="cvr-description">{mappedItem[1].hsDescription}</span>
      </td>
      <td className="text-center">
        <Dropdown className={addMapping ? "" : "disabled-dropdown"}>
          <Dropdown.Toggle
            id="dropdown-basic"
            disabled={storedKey ? storedKey !== mappedItem[0] : ""}
            className={"dropdown-button"}
          >
            Actions
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              as="button"
              className="dropdown-option"
              disabled={storedKey ? storedKey !== mappedItem[0] : ""}
              onClick={() => editMapping(mappedItem[0])}
            >
              Edit
            </Dropdown.Item>
            {mappedItem[1]?.required === true ? (
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip className="dropdown-tool-tip" id="tooltip">
                    Required fields can not be deleted
                  </Tooltip>
                }
              >
                <div>
                  <Dropdown.Item
                    as="button"
                    className="dropdown-option"
                    disabled={
                      mappedItem[1]?.required === true ||
                      (storedKey ? storedKey !== mappedItem[0] : "")
                    }
                    onClick={() => deleteExisting(mappedItem[0])}
                  >
                    Delete
                  </Dropdown.Item>
                </div>
              </OverlayTrigger>
            ) : (
              <Dropdown.Item
                as="button"
                className="dropdown-option"
                disabled={
                  mappedItem[1]?.required === true ||
                  (storedKey ? storedKey !== mappedItem[0] : "")
                }
                onClick={() => deleteExisting(mappedItem[0])}
              >
                Delete
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </>
  );
}

export default MappedRows;
