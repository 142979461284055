import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineCheck } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import arrowIcon from "../assets/images/arrowIcon.png";

//import CVRSelectSearch from "./CVRSelectSearch";
import HubspotSelectSearch from "./HubspotSelectSearch";

import { useDirty } from "../context/dirty";

function EditMapping({
  mappedItem,
  //nonDefaultMappings,
  mappings,
  storedKey,
  //onHandleCVRSelect,
  onHandleHSSelect,
  editCVRValue,
  editHSValue,
  onCancelMapping,
  onUpdateMapping,
  disableHubspotValue,
  selectDefaultProperty,
  enableEditing,
  isCVRCheck,
  item,
  hsProperties,
}) {
  // eslint-disable-next-line no-unused-vars
  const { isDirty, setIsDirty } = useDirty();

  const cancelMapping = () => {
    onCancelMapping();
    setIsDirty(false);
  };

  const updateMapping = () => {
    onUpdateMapping(item, mappedItem[0]);
  };

  const selectDefault = (value) => {
    return selectDefaultProperty(value);
  };

  return (
    <>
      <td className="ps-4 cvr-name-container">
        <span className="cvr-name my-auto">{mappedItem[0]}</span>
        <br />
        <span className="cvr-description my-auto">
          {mappedItem[1].cvrFieldDescription}
        </span>
      </td>
      <td>
        <img src={arrowIcon} alt="arrow-icon" className="arrow-icon" />
      </td>
      <td>
        <HubspotSelectSearch
          //nonDefaultMappings={nonDefaultMappings}
          disableHubspotValue={disableHubspotValue}
          enableEditing={enableEditing}
          onHandleHSSelect={onHandleHSSelect}
          selectDefaultProperty={selectDefaultProperty}
          mappedItem={mappedItem}
          mappings={mappings}
          editCVRValue={editCVRValue}
          isCVRCheck={isCVRCheck}
          item={item}
          hsProperties={hsProperties}
        />
      </td>
      <td className="p-0 d-flex flex-row justify-content-end">
        {(storedKey !== editCVRValue[0] && editCVRValue.length > 0) ||
        (isCVRCheck && Object.keys(editHSValue).length > 0) ||
        (Object.keys(editHSValue).length > 0 &&
          selectDefault(mappedItem[1]).hsValue !== editHSValue.value) ? (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip className="dropdown-tool-tip">Update</Tooltip>}
          >
            <button
              className="add-icon-button"
              onClick={() => updateMapping(item, mappedItem[0])}
            >
              <AiOutlineCheck className="add-icon" />
            </button>
          </OverlayTrigger>
        ) : (
          ""
        )}
        {isCVRCheck ? (
          ""
        ) : (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip className="dropdown-tool-tip">Cancel</Tooltip>}
          >
            <button className="delete-icon-button" onClick={cancelMapping}>
              <ImCross className="delete-icon" />
            </button>
          </OverlayTrigger>
        )}
      </td>
    </>
  );
}

export default EditMapping;
