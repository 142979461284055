import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import AddCompanyInfo from "../components/AddCompanyInfo";
import SetupGuideHeader from "../components/SetupGuideHeader";
import SuccessModal from "../components/SuccessModal";

import { CmsConstructor } from "../helpers/cmsConstructor";
import CmsEditAndSave from "../helpers/CmsEditAndSave";
import { useDirty } from "../context/dirty";

function SetupGuidePage() {
  const {
    cmsInstance,
    setCmsInstance,
    setMessage,
    setShowErrorModal: errorModal,
    showSuccessModal,
    hideSuccessModal,
    message,
  } = useDirty();

  useEffect(() => {
    const cmsInstance = async () => {
      const cmsObj = await CmsConstructor({ setMessage, errorModal });
      setCmsInstance(cmsObj);
    };
    cmsInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SuccessModal
        show={showSuccessModal}
        message={message}
        hideSuccessModal={hideSuccessModal}
      />
      <div className="setup-guide-page">
        <SetupGuideHeader />
        {!cmsInstance ? (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <div className="d-flex flex-row justify-content-center align-items-center">
              <Spinner animation="border" variant="primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </div>
        ) : (
          <>
            <div className="d-flex flex-column align-items-center">
              <CmsEditAndSave />
            </div>
            <div className="container p-5">
              <div className="setup-guide-main-content d-flex flex-column align-items-center">
                <div className="col-11">
                  <div className="setup-guide-content-container">
                    {cmsInstance &&
                      cmsInstance.showRichText(
                        "cmsContent.setupGuide",
                        "setup-guide"
                      )}
                    <AddCompanyInfo />
                    <div>
                      <p className="setup-guide-footer-text">
                        Updated 17/ 02/ 2023
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default SetupGuidePage;
