import {
  Table,
  Accordion,
  Spinner,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { FaSearch, FaCaretDown, FaCaretRight } from "react-icons/fa";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

import SideBar from "./SideBar";
import ErrorModal from "./ErrorModal";
import SuccessModal from "./SuccessModal";
import SaveModal from "./SaveModal";
import EditMapping from "./EditMapping";
import MappedRows from "./MappedRows";
import SearchResultsNotFound from "./SearchResultsNotFound";
import MappingWindowHeader from "./MappingWindowHeader";

import { getUrl } from "../helpers/url";

import { useDirty } from "../context/dirty";

function Configurator({ mappings, getHSProperties, hsProperties, rollbar }) {
  // eslint-disable-next-line no-unused-vars
  const {
    setIsDirty,
    showSaveModal,
    hideSaveModal,
    setDefaultMapping,
    setShowErrorModal,
    setShowSuccessModal,
    setMessage,
    message,
    showErrorModal,
    showSuccessModal,
    isCVRCheck,
    setIsCVRCheck,
    deleteHubspotFields,
    setDeleteHubspotFields,
  } = useDirty();
  const [searchParams] = useSearchParams();
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState({
    basicMappings: {},
    financialMappings: {},
  });
  const [defaultMappings, setDefaultMappings] = useState({});
  const [enableEditing, setEnableEditing] = useState(false);
  const [storedKey, setStoredKey] = useState("");
  const [editCVRValue, setEditCVRValue] = useState([]);
  const [editHSValue, setEditHSValue] = useState({});
  const [loading, setLoading] = useState(false);
  const cvrSort = useRef();
  const hsSort = useRef();
  const [activeItem, setActiveItem] = useState("0");
  const [activeKey, setActiveKey] = useState("basicMappings");

  /**
   * @description This function is used for searching the mappings in the mapping window.
   * @param {String} searchValue entered search input
   */
  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredArray = getFilteredArray(defaultMappings[activeKey]);
      const filter = filteredArray.filter((el) => {
        return (
          el.keyword.toLowerCase().includes(searchValue.toLowerCase()) ||
          el.key.toLowerCase().includes(searchValue.toLowerCase())
        );
      });

      const filteredData = { basicMappings: {}, financialMappings: {} };
      for (const item of filter) {
        filteredData[activeKey][item.key] =
          defaultMappings[activeKey][item.key];
      }
      setSearchResults(filteredData);
    } else {
      setSearchResults(defaultMappings);
    }
  };

  /**
   * @description This function is responsible for filtering the mappings to default and non default mappings
   */
  const filterDefaultMappings = () => {
    let defaultMappings = {};
    let nonDefaultMappings = {};
    if (Object.keys(mappings).length >= 2) {
      defaultMappings = { basicMappings: {}, financialMappings: {} };
      nonDefaultMappings = { basicMappings: {}, financialMappings: {} };
    } else {
      defaultMappings = { basicMappings: {} };
      nonDefaultMappings = { nonDefaultMappings: {} };
    }
    Object.keys(mappings).forEach((item) => {
      //eslint-disable-next-line
      Object.entries(mappings[item]).filter((e) => {
        if (e[1].hsValue === "" && !e[1].required) {
          nonDefaultMappings[item][e[0]] = e[1];
        } else {
          defaultMappings[item][e[0]] = e[1];
        }
      });
      defaultMappings[item] = {
        ...defaultMappings[item],
        ...nonDefaultMappings[item],
      };
    });

    setDefaultMappings(defaultMappings);
    setDefaultMapping(defaultMappings);

    // Check whether CVR Number is mapped in mapping window if not providing option to map the field
    if (
      !defaultMappings["basicMappings"]["CVR Number"].hsValue ||
      defaultMappings["basicMappings"]["CVR Number"].hsValue === ""
    ) {
      setIsCVRCheck(true);
      setEnableEditing(true);
      setStoredKey("CVR Number");
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  };

  /**
   * @description This function is responsible for getting the HubSpot property details
   * @param {Object} value HubSpot property
   * @returns HubSpot property
   */
  const selectDefaultProperty = (value) => {
    if (deleteHubspotFields.includes(value.hsValue)) {
      return {
        hsLabel: `HubSpot custom property deleted`,
        hsValue: "-doesn'texists-",
        type: undefined,
      };
    } else {
      return value;
    }
  };

  /**
   * @description This function is responsible for deleting existing mappings
   * @param {String} value CVR value
   */
  const deleteExistingMapping = (value) => {
    defaultMappings[activeKey][value] = {
      ...defaultMappings[activeKey][value],
      hsValue: "",
      hsLabel: "",
      hsDescription: "",
    };
    setDefaultMappings({ ...defaultMappings });
    setDefaultMapping({ ...defaultMappings });
    setIsDirty(true);
  };

  const onEditMapping = (value) => {
    setEnableEditing(true);
    setStoredKey(value);
    setIsDirty(true);
  };

  const onCancelMapping = () => {
    setEditCVRValue([]);
    setEditHSValue({});
    setEnableEditing(false);
    setStoredKey("");
  };

  /**
   * @description This function is responsible for Editing or Updating existing mappings
   * @param {String} item Selected Accordion
   * @param {String} value Edited HubSpot value
   */
  const onUpdateMapping = (item, value) => {
    if (Object.keys(editHSValue).length > 0) {
      Object.entries(defaultMappings[item]).forEach((el) => {
        if (el[0] === value) {
          defaultMappings[item][el[0]].hsValue = editHSValue.value;
          defaultMappings[item][el[0]].hsLabel = editHSValue.label;
          defaultMappings[item][el[0]].hsDescription = editHSValue.description;
          setDefaultMappings({ ...defaultMappings });
          setDefaultMapping({ ...defaultMappings });
        }
      });
    } else if (
      editCVRValue.length > 0 &&
      Object.keys(editHSValue).length === 0
    ) {
      Object.entries(defaultMappings[item]).forEach((el) => {
        if (el[0] === value) {
          defaultMappings[item][el[0]] = {
            hsLabel: editHSValue.label,
            hsValue: editHSValue.value,
            hsDescription: editHSValue.description,
            cvrFieldDescription:
              defaultMappings[item][el[0]].cvrFieldDescription,
            type: defaultMappings[item][el[0]].type,
            required: (value === "CVR Number" || value === "Name") && true,
          };
          setDefaultMappings({ ...defaultMappings });
          setDefaultMapping({ ...defaultMappings });
        }
      });
    }
    if (defaultMappings["basicMappings"]["CVR Number"].hsValue !== "")
      setIsCVRCheck(false);
    searchItems(searchInput);
    setEnableEditing(false);
    setIsDirty(true);
    setStoredKey("");
    setEditCVRValue([]);
    setEditHSValue({});
    if (cvrSort.current !== undefined) {
      cvrSort.current = !cvrSort.current;
      sortCVRFields();
    }
    if (hsSort.current !== undefined) {
      hsSort.current = !hsSort.current;
      sortHSFields();
    }
  };

  const checkDisableHsValue = (item, hsValue) => {
    if (item === "basicMappings") {
      return Object.entries(defaultMappings["financialMappings"]).find(
        (mapping) => {
          return mapping[1].hsValue === hsValue;
        }
      );
    } else {
      return Object.entries(defaultMappings["basicMappings"]).find(
        (mapping) => {
          return mapping[1].hsValue === hsValue;
        }
      );
    }
  };

  /**
   * @description This function is responsible for disabling the HubSpot properties in the dropdown
   * @param {String} item selected Accordion
   * @param {String} value HubSpot value
   * @returns {Boolean} a boolean value
   */
  const disableHubspotValue = (item, value) => {
    if (
      item === "financialMappings" &&
      Object.keys(defaultMappings["financialMappings"]).length <= 0
    ) {
      return Object.entries(defaultMappings["basicMappings"]).find(
        (mapping) => {
          return mapping[1].hsValue === value;
        }
      );
    } else if (Object.keys(defaultMappings).length <= 1) {
      return Object.entries(defaultMappings[activeKey]).find((mapping) => {
        return mapping[1].hsValue === value;
      });
    } else {
      return Object.entries(defaultMappings[item]).find((mapping) => {
        return mapping[1].hsValue === value || checkDisableHsValue(item, value);
      });
    }
  };

  const hideSuccessModal = () => setShowSuccessModal(false);
  const hideErrorModal = () => setShowErrorModal(false);

  const onHandleCVRSelect = (value) => setEditCVRValue(value);
  const onHandleHSSelect = (value) => setEditHSValue(value);

  /**
   * @description This function is used for sorting mapping based on CVR properties
   */
  const sortCVRFields = () => {
    hsSort.current = undefined;
    if (cvrSort.current === undefined) {
      cvrSort.current = true;
    } else {
      cvrSort.current = !cvrSort.current;
    }

    if (cvrSort.current === true) {
      let asc = {};
      if (Object.keys(defaultMappings).length <= 1) {
        asc = { basicMappings: {} };
      } else {
        if (activeKey === "basicMappings") {
          asc = {
            basicMappings: {},
            financialMappings: { ...defaultMappings["financialMappings"] },
          };
        } else {
          asc = {
            basicMappings: { ...defaultMappings["basicMappings"] },
            financialMappings: {},
          };
        }
      }
      if (searchInput.length > 1) {
        Object.entries(searchResults[activeKey])
          .sort((a, b) => (a > b ? 1 : -1))
          .forEach((el) => {
            asc[activeKey][el[0]] = el[1];
          });
        setSearchResults(asc);
      } else {
        Object.entries(defaultMappings[activeKey])
          .sort((a, b) => (a > b ? 1 : -1))
          .forEach((el) => {
            asc[activeKey][el[0]] = el[1];
          });
        setDefaultMappings(asc);
      }
    } else {
      let desc = {};
      if (Object.keys(defaultMappings).length <= 1) {
        desc = { basicMappings: {} };
      } else {
        if (activeKey === "basicMappings") {
          desc = {
            basicMappings: {},
            financialMappings: { ...defaultMappings["financialMappings"] },
          };
        } else {
          desc = {
            basicMappings: { ...defaultMappings["basicMappings"] },
            financialMappings: {},
          };
        }
      }
      if (searchInput.length > 1) {
        Object.entries(searchResults[activeKey])
          .sort((a, b) => (a > b ? -1 : 1))
          .forEach((el) => {
            desc[activeKey][el[0]] = el[1];
          });
        setSearchResults(desc);
      } else {
        Object.entries(defaultMappings[activeKey])
          .sort((a, b) => (a > b ? -1 : 1))
          .forEach((el) => {
            desc[activeKey][el[0]] = el[1];
          });
        setDefaultMappings(desc);
      }
    }
  };

  /**
   * @description This function is used for filtering mappings
   * @param {Object} mappingObjs mappings object
   * @returns {Array} filtered array
   */
  const getFilteredArray = (mappingObjs) => {
    let filteredArray = [];
    Object.entries(mappingObjs).forEach((mapping) => {
      if (deleteHubspotFields.includes(mapping[1].hsValue)) {
        filteredArray.push({
          keyword: "HubSpot custom property deleted",
          key: mapping[0],
        });
      }
      if (mapping[1].hsValue === "") {
        filteredArray.push({
          keyword: "",
          key: mapping[0],
        });
      }
      filteredArray.push({
        keyword: mapping[1].hsLabel,
        key: mapping[0],
      });
    });
    return filteredArray;
  };

  /**
   * @description This function is used for sorting mappings based on HubSpot properties
   */
  const sortHSFields = () => {
    cvrSort.current = undefined;
    if (hsSort.current === undefined) {
      hsSort.current = true;
    } else {
      hsSort.current = !hsSort.current;
    }

    let filteredArray = getFilteredArray(
      searchInput.length > 1
        ? searchResults[activeKey]
        : defaultMappings[activeKey]
    );

    if (hsSort.current === true) {
      let asc;
      if (Object.keys(defaultMappings).length <= 1) {
        asc = { basicMappings: {} };
      } else {
        if (activeKey === "basicMappings") {
          asc = {
            basicMappings: {},
            financialMappings: { ...defaultMappings["financialMappings"] },
          };
        } else {
          asc = {
            basicMappings: { ...defaultMappings["basicMappings"] },
            financialMappings: {},
          };
        }
      }
      filteredArray
        .sort((first, second) =>
          first.keyword.toLowerCase() > second.keyword.toLowerCase() ? 1 : -1
        )
        .forEach((mapping) => {
          asc[activeKey][mapping.key] =
            searchInput > 1
              ? searchResults[activeKey][mapping.key]
              : defaultMappings[activeKey][mapping.key];
        });
      searchInput.length > 1 ? setSearchResults(asc) : setDefaultMappings(asc);
    } else {
      let desc;
      if (Object.keys(defaultMappings).length <= 1) {
        desc = { basicMappings: {} };
      } else {
        if (activeKey === "basicMappings") {
          desc = {
            basicMappings: {},
            financialMappings: { ...defaultMappings["financialMappings"] },
          };
        } else {
          desc = {
            basicMappings: { ...defaultMappings["basicMappings"] },
            financialMappings: {},
          };
        }
      }
      // custom sorting function by comparing values as first and second inside the filtered array
      filteredArray
        .sort((first, second) =>
          first.keyword.toLowerCase() > second.keyword.toLowerCase() ? -1 : 1
        )
        .forEach((mapping) => {
          desc[activeKey][mapping.key] =
            searchInput > 1
              ? searchResults[activeKey][mapping.key]
              : defaultMappings[activeKey][mapping.key];
        });
      searchInput.length > 1
        ? setSearchResults(desc)
        : setDefaultMappings(desc);
    }
  };

  /**
   * @description This function is responsible for validating hubspot fields
   * @param {object} defaultMappings mapped rows
   */
  const validateHubspotProperties = (defaultMappings, hsProperties) => {
    setDeleteHubspotFields([]);
    const emptyArray = [];
    const newSet = new Set(hsProperties.map((hsProperty) => hsProperty.value));

    Object.keys(defaultMappings).forEach((item) => {
      Object.entries(defaultMappings[item]).forEach((mapping) => {
        if (mapping[1].hsValue && !newSet.has(mapping[1].hsValue)) {
          emptyArray.push(mapping[1].hsValue);
        }
      });
    });
    setDeleteHubspotFields(emptyArray);
  };

  /**
   * @description This function is used for setting the states based on accordion
   * @param {String} eventKey index for accordion
   */
  const storeActiveAccordion = (eventKey) => {
    setActiveItem(eventKey);
    setEditCVRValue([]);
    setEditHSValue({});
    setSearchInput("");
    cvrSort.current = undefined;
    hsSort.current = undefined;
    if (
      eventKey === "1" &&
      Object.keys(defaultMappings.financialMappings).length <= 0
    ) {
      setEnableEditing(false);
    }
  };

  const changeHeader = (item) => {
    const headerTitle = item.split("Mappings");
    return headerTitle[0].toUpperCase();
  };

  const onCheckCVRMapping = (mappedItem) => {
    if (
      (!defaultMappings["basicMappings"]["CVR Number"] ||
        defaultMappings["basicMappings"]["CVR Number"].hsValue === "") &&
      activeKey === "financialMappings"
    ) {
      if (mappedItem[0] === "CVR Number" && mappedItem[1].hsValue === "") {
        const r = { label: "Select", value: "", type: "string" };
        const index = hsProperties.findIndex(
          (item) => item.label === r.label || item.label === ""
        );
        if (index === -1) {
          hsProperties.push(r);
        }
        return mappedItem;
      } else {
        return mappedItem;
      }
    } else {
      if (mappedItem[1].hsValue === "" && mappedItem[0] === "CVR Number") {
        const index = hsProperties.findIndex((item) => {
          return item.label === "Select";
        });
        if (index !== -1) {
          hsProperties[index].label = "";
        }
        setEnableEditing(true);
        return mappedItem;
      } else {
        return mappedItem;
      }
    }
  };

  useEffect(() => {
    filterDefaultMappings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappings]);

  useEffect(() => {
    if (
      defaultMappings &&
      Object.keys(defaultMappings).length > 0 &&
      hsProperties &&
      hsProperties.length > 0
    ) {
      validateHubspotProperties(defaultMappings, hsProperties);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultMappings, hsProperties]);

  return (
    <div>
      <SuccessModal
        show={showSuccessModal}
        message={message}
        hideSuccessModal={hideSuccessModal}
      />
      <ErrorModal
        show={showErrorModal}
        message={message}
        hideErrorModal={hideErrorModal}
      />
      <SaveModal show={showSaveModal} hideSaveModal={hideSaveModal} />
      <div className="search-bar-main-container">
        <div className="search-bar-sub-container">
          <div className="mapping-search d-flex my-3 my-md-0">
            <input
              type="search"
              placeholder="Search"
              onChange={(e) => searchItems(e.target.value)}
              className="input-search"
              value={searchInput}
            />
            <span className="my-auto">
              <FaSearch size={15} className="me-3 search-icon" />
            </span>
          </div>
          <div className="sidebar-btn my-auto">
            <SideBar getHSProperties={getHSProperties} rollbar={rollbar} />
          </div>
        </div>
      </div>
      <Accordion
        defaultActiveKey={activeItem}
        onSelect={(eventKey) => storeActiveAccordion(eventKey)}
        className="my-3"
      >
        {defaultMappings &&
          Object.keys(defaultMappings).map((item, index) => (
            <Accordion.Item
              eventKey={index.toString()}
              key={index.toString()}
              className="mt-2"
            >
              <Accordion.Header onClick={() => setActiveKey(item)}>
                {activeItem === index.toString() ? (
                  <FaCaretDown className="me-1" size={12} />
                ) : (
                  <FaCaretRight className="me-1" size={12} />
                )}
                <span className="accordion-title">
                  {changeHeader(item)} DATA
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {Object.keys(searchResults[item]).length === 0 &&
                searchInput.length > 1 ? (
                  <SearchResultsNotFound
                    displayText={"No Mappings Found."}
                    noteText={"Try changing your filters."}
                  />
                ) : (
                  <Table responsive className="table my-2">
                    <thead className="window-header">
                      <MappingWindowHeader
                        sortCVRFields={sortCVRFields}
                        sortHSFields={sortHSFields}
                        hsSort={hsSort}
                        cvrSort={cvrSort}
                      />
                    </thead>
                    <tbody className="table-body">
                      {Object.entries(
                        searchInput.length > 1
                          ? searchResults[item]
                          : defaultMappings[item]
                      ).map((el) => (
                        <tr
                          key={el[0]}
                          className={
                            enableEditing && storedKey === el[0]
                              ? "add-mapping-container"
                              : "mapping-container"
                          }
                        >
                          {enableEditing && storedKey === el[0] ? (
                            // edit mapping row
                            <EditMapping
                              mappedItem={el}
                              mappings={mappings[item]}
                              storedKey={storedKey}
                              onHandleCVRSelect={onHandleCVRSelect}
                              onHandleHSSelect={onHandleHSSelect}
                              editCVRValue={editCVRValue}
                              editHSValue={editHSValue}
                              onCancelMapping={onCancelMapping}
                              onUpdateMapping={onUpdateMapping}
                              disableHubspotValue={disableHubspotValue}
                              selectDefaultProperty={selectDefaultProperty}
                              enableEditing={enableEditing}
                              isCVRCheck={isCVRCheck}
                              item={item}
                              hsProperties={hsProperties}
                            />
                          ) : (
                            // Mapped rows
                            <MappedRows
                              mappedItem={onCheckCVRMapping(el)}
                              selectDefaultProperty={selectDefaultProperty}
                              onEditMapping={onEditMapping}
                              deleteExistingMapping={deleteExistingMapping}
                              storedKey={storedKey}
                            />
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Accordion.Body>
            </Accordion.Item>
          ))}
      </Accordion>
      <div className="d-flex justify-content-end">
        {Object.keys(searchResults).length === 0 && searchInput.length > 1 ? (
          ""
        ) : (
          <>
            {deleteHubspotFields.length > 0 ? (
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip className="tool-tip">
                    Please remap the fields for which custom property has been
                    deleted
                  </Tooltip>
                }
              >
                <button className="btn add-button">Save Mappings</button>
              </OverlayTrigger>
            ) : (
              <button
                disabled={enableEditing || deleteHubspotFields.length > 0}
                onClick={() =>
                  saveMappings({
                    setLoading,
                    mappings,
                    defaultMappings,
                    searchParams,
                    setIsDirty,
                    setMessage,
                    setShowSuccessModal,
                    rollbar,
                    setShowErrorModal,
                  })
                }
                className="btn add-button"
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span>Save Mappings</span>
                )}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Configurator;

/**
 * @description This function is used for saving mappings
 */
export const saveMappings = async ({
  setLoading,
  mappings,
  defaultMappings,
  searchParams,
  setIsDirty,
  setMessage,
  setShowSuccessModal,
  rollbar,
  setShowErrorModal,
}) => {
  try {
    setLoading(true);
    let fields = {};
    if (Object.keys(mappings).length >= 2) {
      fields = { basicMappings: {}, financialMappings: {} };
    } else {
      fields = { basicMappings: {} };
    }
    Object.keys(defaultMappings).forEach((item) => {
      for (const key in defaultMappings[item]) {
        if (
          defaultMappings[item][key].hsValue ||
          defaultMappings[item][key].hsValue === ""
        ) {
          fields[item][key] = {
            hsLabel: defaultMappings[item][key].hsLabel,
            hsValue: defaultMappings[item][key].hsValue,
            hsDescription: defaultMappings[item][key].hsDescription,
          };
        }
      }
    });
    const portalId = searchParams.get("portalId");
    await axios.post(getUrl("REACT_APP_SAVE_MAPPING_URL"), {
      portalId,
      fields,
    });
    setIsDirty(false);
    setMessage(
      "cmsContent.notifications.mappingWindow.successContent.saveMapping.message"
    );
    setShowSuccessModal(true);
  } catch (error) {
    console.log(error);
    rollbar.error("Error Save Mappings", error, {
      portalId: searchParams.get("portalId"),
    });
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    setMessage(errorMessage);
    setShowErrorModal(true);
  } finally {
    setLoading(false);
  }
};
