import { Button, Spinner, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import ErrorModal from "./ErrorModal";
import SuccessModal from "./SuccessModal";

import { getUrl, getStripeUrl } from "../helpers/url";

import { useDirty } from "../context/dirty";

function PricingCard({
  subscriptionDetails,
  setSubscriptionDetails,
  rollbar,
  fetchPlanData,
  setCustomerKey,
  customerKey,
}) {
  const { cmsInstance } = useDirty();
  const [upgradeLoading, setUpgradeLoading] = useState(false);
  const [downgradeLoading, setDowngradeLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  /**
   * @description This function is responsible for opening new window for adding card in stripe.
   */
  const openNewWindow = () => {
    window.addEventListener("message", (e) => {
      if (e.data?.paymentStatus) {
        console.log(e.data?.paymentStatus);
        setPaymentStatus(e.data?.paymentStatus);
      }
    });
  };

  /**
   * This function is responsible for upgrading plan
   */
  const onUpgradePlan = async () => {
    try {
      setUpgradeLoading(true);
      const portalId = searchParams.get("portalId");
      const userId = searchParams.get("userId");

      // Find/Create Customer in stripe
      const { data: customer } = await axios.post(
        getStripeUrl("REACT_APP_FIND_CREATE_CUSTOMER"),
        {
          appSlug: "hubspotCvrLookup",
          metaData: {
            hubspotUserId: userId,
          },
        }
      );
      setCustomerKey(customer.id);

      // Create Checkout
      const { data: res } = await axios.post(
        getStripeUrl("REACT_APP_STRIPE_CHECKOUT"),
        {
          customerKey: customer.id,
          appSlug: "hubspotCvrLookup",
          plan: "premium",
          successUrl: `${window.location.origin}/redirect?portalId=${portalId}&userId=${userId}&paymentStatus=cardAdded`,
          cancelUrl: `${window.location.origin}/redirect?portalId=${portalId}&userId=${userId}&paymentStatus=failed`,
          subscriptionData: {
            description: `Subscription active for the company with portal ${portalId}`,
            metaData: {
              portalId,
            },
          },
        }
      );

      if (res.url) {
        // redirects to the stripe window
        // const params = `toolbar=no, menubar=no, width=600, height=700, top=100, left=100`;
        window.open(res.url, "_blank");
      }
    } catch (error) {
      console.log(error);
      rollbar.error("Error Updating Plan", error, {
        portalId: searchParams.get("portalId"),
      });
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMessage(message);
      setShowErrorModal(true);
    } finally {
      setUpgradeLoading(false);
    }
  };

  /**
   * This function is responsible for cancelling the subscription
   */
  const onCancelSubscription = async () => {
    try {
      setDowngradeLoading(true);

      const portalId = searchParams.get("portalId");
      const userId = searchParams.get("userId");

      await axios.post(getUrl("REACT_APP_SUBSCRIPTION_CANCEL"), {
        portalId,
        userId,
      });
      await fetchPlanData();
      setSuccessMessage(
        "cmsContent.notifications.subscriptionPage.successContent.cancelSubscription.message"
      );
      setShowSuccessModal(true);
    } catch (error) {
      console.log(error);
      rollbar.error("Error Downgrading Plan", error, {
        portalId: searchParams.get("portalId"),
      });
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMessage(message);
      setShowErrorModal(true);
    }
    setDowngradeLoading(false);
  };

  /**
   * This function is used for checking the payment status
   */
  const paymentStatusCheck = async () => {
    const portalId = searchParams.get("portalId");
    const userId = searchParams.get("userId");
    if (paymentStatus === "cardAdded") {
      subscriptionDetails.plan = "premium";
      subscriptionDetails.usageCount = 0;
      subscriptionDetails.customerKey = customerKey;
      setSubscriptionDetails(subscriptionDetails);
      navigate(
        `..${window.location.pathname}?portalId=${portalId}&userId=${userId}&paymentStatus=none`
      );
      setSuccessMessage(
        "cmsContent.notifications.subscriptionPage.successContent.upgradePlan.message"
      );
      setShowSuccessModal(true);
    } else if (paymentStatus === "failed") {
      setErrorMessage(
        "cmsContent.notifications.subscriptionPage.failContent.paymentFailed.message"
      );
      setShowErrorModal(true);
    }
    setPaymentStatus("");
  };

  const hideSuccessModal = () => setShowSuccessModal(false);
  const hideErrorModal = () => setShowErrorModal(false);

  /**
   * This function is responsible for rendering buttons
   */
  const renderButtons = (card) => {
    if (subscriptionDetails.plan === "basic" && card.name === "Basic") {
      return (
        <>
          {subscriptionDetails.usageCount >= 15 ? (
            <Button className="pricing-card-cancel-button mt-3 mb-3" disabled>
              Trial Expired
            </Button>
          ) : (
            <Button className="pricing-card-button-disable mt-3 mb-3" disabled>
              Selected
            </Button>
          )}
        </>
      );
    } else if (
      subscriptionDetails.plan === "basic" &&
      card.name === "Premium"
    ) {
      return (
        <Button
          disabled={upgradeLoading}
          className="pricing-card-button mt-3 mb-3"
          onClick={onUpgradePlan}
        >
          {upgradeLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <span>Upgrade</span>
          )}
        </Button>
      );
    } else if (
      subscriptionDetails.plan === "premium" &&
      card.name === "Premium"
    ) {
      return (
        <div className="d-flex flex-column mt-3 mb-3">
          <Button className="pricing-card-button-disable " disabled>
            Selected
          </Button>
          <Button
            className="pricing-card-cancel-button mt-2"
            onClick={onCancelSubscription}
            disabled={downgradeLoading}
          >
            {downgradeLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Cancel Subscription</span>
            )}
          </Button>
        </div>
      );
    } else if (
      subscriptionDetails.plan === "premium-cancelled" &&
      card.name === "Premium"
    ) {
      return (
        <Button
          disabled={upgradeLoading}
          className="pricing-card-button mt-3 mb-3"
          onClick={onUpgradePlan}
        >
          {upgradeLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <span>Upgrade</span>
          )}
        </Button>
      );
    }
  };

  const cardData = [
    {
      id: 1,
      name: "Basic",
      text: "All the basics for business that are just getting started.",
      price: "DKK 0",
      path: "card1",
      points: [
        "15 Trial credits",
        "Basic and financial data",
        "Customise mappings and Bulk update",
        "Search companies based on name and CVR number",
      ],
    },
    {
      id: 2,
      name: "Premium",
      text: "Premium plan for the businesses that have a high search volume.",
      price: "DKK 20",
      path: "card2",
      points: [
        "0.25 DKK/ sync",
        "Basic and financial data",
        "Customise mappings and Bulk update",
        "Search companies based on name and CVR number",
      ],
    },
  ];

  useEffect(() => {
    openNewWindow();
    if (paymentStatus) {
      paymentStatusCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatus]);

  return (
    <>
      <SuccessModal
        show={showSuccessModal}
        message={successMessage}
        hideSuccessModal={hideSuccessModal}
      />
      <ErrorModal
        show={showErrorModal}
        message={errorMessage}
        hideErrorModal={hideErrorModal}
      />
      <Row
        className={`pricing-cards-container ${
          (subscriptionDetails.plan === "premium" ||
            subscriptionDetails.plan === "premium-cancelled") &&
          "d-flex flex-row justify-content-center"
        }`}
      >
        {cardData &&
          subscriptionDetails &&
          Object.keys(subscriptionDetails).length > 0 &&
          cardData.map((card) => (
            <Col
              sm={8}
              md={6}
              key={card.id}
              className={`pricing-list mt-3 h-100 ${
                (subscriptionDetails.plan === "premium" ||
                  subscriptionDetails.plan === "premium-cancelled") &&
                card.name === "Basic" &&
                "d-none"
              }`}
            >
              <div className="pricing-card h-100">
                <div className="pricing-card-header p-4">
                  {cmsInstance &&
                    cmsInstance.showRichText(
                      `cmsContent.subscription.${card.path}.plan`,
                      "card-pricing"
                    )}
                </div>
                <div>
                  {cmsInstance &&
                    cmsInstance.showRichText(
                      `cmsContent.subscription.${card.path}.price`,
                      "card-price"
                    )}
                  <div className="d-flex flex-row justify-content-center align-items-center mx-3">
                    {cmsInstance &&
                      cmsInstance.showRichText(
                        `cmsContent.subscription.${card.path}.points`,
                        "pricing-points"
                      )}
                  </div>
                </div>
                <>{renderButtons(card)}</>
              </div>
            </Col>
          ))}
      </Row>
    </>
  );
}

export default PricingCard;
