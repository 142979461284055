import AddCompanyInfo from "../components/AddCompanyInfo";

function Confirmation() {
  return (
    <div>
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-11">
            <div className="confirmation-page-box my-5">
              <AddCompanyInfo />
              <p className="confirmation-page-footer-date pb-2">
                Updated 17/ 02/ 2023
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
