import { useState } from "react";
import { Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useDirty } from "../context/dirty";
import { CmsConstructor } from "./cmsConstructor";

const CmsEditAndSave = () => {
  const [closeBtn, setCloseBtn] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const {
    cmsInstance,
    isEdit,
    allowedUsers,
    setIsAllowedUser,
    isAllowedUser,
    setIsEdit,
    setCmsInstance,
    setMessage,
    setShowSuccessModal,
    setShowErrorModal,
  } = useDirty();

  const [searchParams] = useSearchParams();
  const portalId = searchParams.get("portalId");
  const userId = searchParams.get("userId");

  let isUserAllowed = false;
  for (const user of allowedUsers) {
    if (window.location.pathname === "/mappingwindow") {
      if (user.portalId === portalId) {
        isUserAllowed = true;
        break;
      }
    } else {
      if (user.portalId === portalId && user.userId === userId) {
        isUserAllowed = true;
        break;
      }
    }
  }

  if (isUserAllowed !== isAllowedUser && closeBtn === false) {
    setIsAllowedUser(isUserAllowed);
  }

  const handleEdit = () => {
    setIsEdit(true);
    setShowSaveBtn(true);
  };

  const handleClose = () => {
    setIsAllowedUser(false);
    setCloseBtn(true);
    setIsEdit(false);
  };

  const handlePreview = () => {
    setIsEdit(false);
  };

  const handleSaveChanges = () => {
    cmsInstance.saveContentChanges({
      setMessage,
      setShowSuccessModal,
      setShowErrorModal,
    });
    setIsEdit(false);
    setShowSaveBtn(false);
  };

  const handleCancel = async () => {
    setIsEdit(false);
    setShowSaveBtn(false);
    const pmCmsInstance = await CmsConstructor(setMessage);
    setCmsInstance(pmCmsInstance);
  };

  return (
    <>
      {isAllowedUser && (
        <div className="pms-dialog-box d-flex flex-column justify-content-between mt-4">
          <div>
            <button className="close-button" onClick={handleClose}>
              &#x2716;
            </button>
          </div>
          <p className="text-center mt-2">
            To change the content click on the edit button below.
          </p>
          <div className="d-flex flex-row justify-content-end me-3 mb-3">
            {window.location.pathname !== "/edit-notifications" && (
              <Button className="edit-notification-btn me-2">
                <a
                  href={`/edit-notifications?portalId=${portalId}&userId=${userId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Edit notifications
                </a>
              </Button>
            )}
            {showSaveBtn && (
              <Button
                className="save-content-changes-btn"
                onClick={() => handleSaveChanges()}
              >
                Save
              </Button>
            )}
            {!isEdit && (
              <Button
                className="save-content-changes-btn ms-2"
                onClick={() => handleEdit()}
              >
                Edit
              </Button>
            )}

            {isEdit && (
              <button
                onClick={() => handlePreview()}
                className="preview-btn ms-2"
              >
                Preview
              </button>
            )}
            {isEdit && (
              <button
                className="cancel-button px-3 ms-2"
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CmsEditAndSave;
