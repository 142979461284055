import { Modal } from "react-bootstrap";
import { ImCross } from "react-icons/im";
import { useSearchParams } from "react-router-dom";

import { useDirty } from "../context/dirty";

function ErrorModal({ show, message, hideErrorModal, displayUrl = false }) {
  const hideModal = () => {
    hideErrorModal();
  };

  const { cmsInstance } = useDirty();

  const [searchParams] = useSearchParams();

  const portalId = searchParams.get("portalId");
  const userId = searchParams.get("userId");

  const checkContentFrom = () => {
    const errorMessage = message.split(".");
    const messageFrom = errorMessage.includes("cmsContent");
    return messageFrom;
  };

  return (
    <div>
      <Modal
        className="my-modal d-flex flex-row justify-content-center"
        show={show}
        onHide={hideModal}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="error-modal-header">
            <div className="modal-error-box mx-auto" onClick={hideModal}>
              <ImCross className="modal-cross-icon" />
            </div>
            {checkContentFrom() ? (
              <>
                {cmsInstance &&
                  cmsInstance.showRichText(message, "modal-message")}
              </>
            ) : (
              <p className="modal-message p-3">
                {message}{" "}
                {displayUrl && (
                  <span>
                    To upgrade subscription please click{" "}
                    <a
                      href={`/subscription?portalId=${portalId}&userId=${userId}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      here
                    </a>
                  </span>
                )}
              </p>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ErrorModal;
