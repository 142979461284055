import React from "react";
import { ProgressBar, Spinner } from "react-bootstrap";

const ProgressBarSection = ({
  bulkUpdateStats,
  delayTime,
  delayTimeCalculation,
}) => {
  const { pushedRecords, processedRecords, failedRecords } = bulkUpdateStats;

  // calculate percentage
  const percentageCalculation = () => {
    if (pushedRecords === 0) {
      return 0;
    } else {
      return Math.floor(
        ((processedRecords + failedRecords) / pushedRecords) * 100
      );
    }
  };

  return (
    <div>
      {bulkUpdateStats &&
      Object.keys(bulkUpdateStats).length > 0 &&
      bulkUpdateStats.pushedRecords > 0 ? (
        <div>
          <p className="progress-bar-bold-text">
            {pushedRecords === processedRecords + failedRecords ||
            delayTime >= delayTimeCalculation(pushedRecords) ? (
              <span>Update complete</span>
            ) : (
              <span>Updating</span>
            )}
          </p>
          <div className="d-flex flex-row justify-content-between progress-bar-normal-text">
            <span>Progress...</span>
            <span>{percentageCalculation()}%</span>
          </div>
          <ProgressBar
            min={0}
            max={pushedRecords}
            now={failedRecords + processedRecords}
            className="my-3"
          />
          <p className="progress-bar-normal-text">
            All {pushedRecords} companies are being updated.
          </p>
          <p className="progress-bar-normal-text">
            Companies successfully updated: <b>{processedRecords}</b>
            <br />
            Companies failed to update: <b>{failedRecords}</b>
          </p>
          <p className="progress-bar-normal-text">
            <b>Note: </b>
            <span>
              The process will be completed in the background, so you can close
              the popup window and can come back to view the progress.
            </span>
          </p>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center my-3">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Spinner
              animation="border"
              variant="success"
              role="status"
            ></Spinner>
            <p className="progress-bar-bold-text mt-3">update in progress...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgressBarSection;
