import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Modal, Button, Spinner } from "react-bootstrap";

import { saveMappings } from "./Configurator";
import { fetchMappings } from "../pages/MappingWindow";

import { useDirty } from "../context/dirty";

import { useRollbar } from "@rollbar/react";

function SaveModal({ show, hideSaveModal }) {
  const [loading, setLoading] = useState(false);
  const [discardBtnLoading, setDisCardBtnLoading] = useState(false);

  const {
    mappings,
    defaultMappings,
    setIsDirty,
    setMessage,
    setShowErrorModal,
    setShowSuccessModal,
    setGetMappings,
    isCVRCheck,
    isDirty,
    deleteHubspotFields,
  } = useDirty();
  const [searchParams] = useSearchParams();
  const rollbar = useRollbar();
  const discardChanges = async () => {
    setDisCardBtnLoading(true);
    await fetchMappings({
      searchParams,
      setGetMappings,
      setLoading,
      rollbar,
      setMessage,
      setShowErrorModal,
    });
    setDisCardBtnLoading(false);
    hideSaveModal();
  };

  const saveChanges = async () => {
    setLoading(true);
    await saveMappings({
      setLoading,
      mappings,
      defaultMappings,
      searchParams,
      setIsDirty,
      setMessage,
      setShowSuccessModal,
      rollbar,
      setShowErrorModal,
    });
    setLoading(false);
    setDisCardBtnLoading(false);
    hideSaveModal();
  };

  const renderText = () => {
    if (isCVRCheck) {
      return "Please map the CVR Number as it is required field to continue.";
    } else if (deleteHubspotFields.length > 0) {
      return "Please remap the fields for which custom property has been deleted.";
    } else if (isDirty) {
      return "You've made changes that haven't been saved yet. Please either save or discard these changes to continue.";
    }
  };

  const renderBtns = () => {
    if (isCVRCheck || deleteHubspotFields.length > 0) {
      return (
        <button
          className="btn add-button"
          onClick={() => {
            window.scrollTo(0, 400);
            hideSaveModal();
          }}
        >
          Edit
        </button>
      );
    } else if (isDirty) {
      return (
        <>
          <Button
            className="me-3"
            variant="danger"
            onClick={() => discardChanges()}
            style={{ width: "100px" }}
          >
            {discardBtnLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Discard</span>
            )}
          </Button>
          <button onClick={() => saveChanges()} className="btn add-button">
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Save changes</span>
            )}
          </button>
        </>
      );
    }
  };

  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <p>{renderText()}</p>
        <div className="d-flex flex-row justify-content-end">
          {renderBtns()}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SaveModal;
